export const CContentRouteNames = {
  default: 'page.content',
  cases: 'page.cases',
  demo: {
    default: 'page.demo.content',
    cases: 'page.demo.cases',
    books: {
      show: 'page.demo.books.show',
    },
  },
  books: {
    index: 'page.books.index',
    show: 'page.books.show',
  },
  blog: {
    index: 'page.blog.index',
    show: 'page.blog.show',
  },
}
