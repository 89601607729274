import { ref, Ref, watch } from 'vue'
import { useElementBounding, useScriptTag, useTimeoutFn } from '@vueuse/core'
import { EAuthenticationProviders } from '@/domain/Authentication/contracts/EAuthenticationProviders'
import { useConfig } from '@/app/services/useConfig'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'
import type { TGoogleSignInSecretPayload } from '@/domain/Authentication/contracts/TGoogleSignInSecretPayload'

const setupGoogleButton = (
  buttonContainer: Ref<HTMLElement | null>,
  referenceButtonWidth: Ref<number>,
  setupGoogleButtonCallCount: Ref<number>,
) => {
  setupGoogleButtonCallCount.value = setupGoogleButtonCallCount.value + 1
  const googleClientId = useConfig().get().authentication.google.clientId

  if (!googleClientId) {
    // eslint-disable-next-line no-console
    console.error(`Google Client ID isn't specified`)
    return
  }

  if (!buttonContainer.value) {
    throw new Error('Container for google sign in button should exist')
  }

  window.google.accounts.id.initialize({
    client_id: googleClientId,
    ux_mode: 'popup',
    use_fedcm_for_prompt: true,
    callback: async (payload: TGoogleSignInSecretPayload) => {
      await useAuthentication().signInWith(EAuthenticationProviders.GOOGLE, {
        googleSignInPayload: payload,
      })
    },
  })

  window.google.accounts.id.renderButton(buttonContainer.value, {
    theme: 'outline',
    shape: 'rectangular',
    size: 'large',
    logo_alignment: 'left',
    width: referenceButtonWidth.value.toString(),
    locale: 'en-us',
  })
}

export const useGoogleSignButton = () => {
  const _scriptLoaded = ref(false)
  const _referenceButton = ref<HTMLElement | null>(null)
  const _buttonContainer = ref<HTMLElement | null>(null)
  const _setupGoogleButtonCallCount = ref(0)
  const { width: referenceButtonWidth } = useElementBounding(_referenceButton)

  watch([_scriptLoaded, referenceButtonWidth], async ([newScriptLoaded, newWidth]) => {
    if (newWidth && newScriptLoaded) {
      runDelayedSetupGoogleButton()
    }
  })

  useScriptTag('https://accounts.google.com/gsi/client', () => {
    _scriptLoaded.value = true
  })

  const { start: runDelayedSetupGoogleButton } = useTimeoutFn(
    () => {
      setupGoogleButton(
        _buttonContainer,
        referenceButtonWidth,
        _setupGoogleButtonCallCount,
      )
      if (_setupGoogleButtonCallCount.value < 2) {
        runDelayedSetupGoogleButton()
      }
    },
    200,
    { immediate: false },
  )

  return {
    referenceButton: _referenceButton,
    buttonContainer: _buttonContainer,
  }
}
