import type { AxiosError } from 'axios'
import type { NavigationGuardNext, RouteLocationNormalized, Router } from 'vue-router'
import type { NotificationProps } from '@/domain/notifications/services/useNotificationsStore'
import { createNotification } from '@/domain/notifications/services/notificationFactory'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import { useDocumentOrigin } from '@/domain/documents/composables/useDocumentOrigin'
import { EDocumentInteractions } from '@/domain/documents/contracts/EDocumentInteractions'
import { CDocumentOriginUnknown } from '@/domain/documents/contracts/CDocumentOriginUnknown'

export const handleCaseNotFound = async (
  error: AxiosError,
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  exposeOneNotification: (
    notification: NotificationProps,
    exposureDuration?: number,
  ) => void,
  shareId: string,
  router: Router,
): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { status } = error?.response ?? {}

  const notification = createNotification(
    `The guide you're trying to access is not found`,
    'error',
  )
  exposeOneNotification(notification, 10)

  const documentProperties = await useDocumentOrigin({ router: router }).fromSnapshot(
    {
      interaction_type: EDocumentInteractions.intendedToCreateADocumentFromSnapshot,
      route: to.name,
      route_query: { ...to.query },
      route_params: { ...to.params },
      guard: 'shareTopicNavigationGuard',
      error: `Document could not be found redirect to '${CRouteNames.home}'`,
      status: status,
    },
    to.path,
  )

  if (!documentProperties?.document) {
    documentProperties['document'] = undefined
  }

  if (
    (documentProperties?.document && !documentProperties?.document?.origin_ref) ||
    documentProperties?.document?.origin_ref === CDocumentOriginUnknown
  ) {
    documentProperties.document['origin_ref'] = shareId
  }

  captureWithUser(EUserEvents.receivedError, documentProperties)

  next({
    name: CRouteNames.home,
    replace: true,
  })
}
