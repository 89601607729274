<template>
  <section class="relative flex flex-col w-full items-center" :class="[containerClasses]">
    <form
      class="relative z-0 w-full min-w-72 xs:min-w-xs max-w-2xl flex gap-0 rounded-xl border-none focus-within:ring-1 focus-within:ring-state-error-focus focus-within:ring-offset-2"
    >
      <label :for="props.idAttribute" class="sr-only">Enter your challenge</label>
      <textarea
        :id="props.idAttribute"
        ref="promptInputElement"
        v-model="promptValue"
        :autofocus="autoFocusAction"
        :placeholder="placeholder"
        class="flex-1 py-[9px] pr-2.5 pl-4 text-base-content bg-base-100 border border-y border-l border-r-0 focus:ring-0 border-base-300 focus:border-base-300 focus:outline-none resize-none rounded-l-xl"
        :style="{
          maxHeight: `${maxPromptHeight}px`,
          minHeight: `${minPromptHeight}px`,
        }"
        :class="[
          conditionallyApplyHideScrollBarClass,
          ...(isSubmitting ? ['cursor-progress'] : []),
        ]"
        rows="1"
        :tabindex="props.tabIndex"
        :data-scroll-target-id="props.scrollTargetId"
        :disabled="isSubmitting"
      />
      <div class="w-0.5 border-y border-base-300 bg-base-100"></div>
      <button
        type="submit"
        class="px-3 py-2 font-bold whitespace-nowrap border border-state-error border-solid hover:bg-state-error-focus hover:border-state-error-focus rounded-r-xl"
        :class="[
          ...(isSubmissionDisabled
            ? [
                'cursor-not-allowed',
                '!bg-base-100',
                'text-state-error',
                'hover:!text-state-error-content',
                'hover:!bg-state-error-focus',
              ]
            : ['text-state-error-content', 'bg-state-error']),
          ...(isSubmitting
            ? ['bg-state-error/60', 'hover:bg-state-error-focus/60', 'cursor-progress']
            : []),
        ]"
        :disabled="isSubmissionDisabled || isSubmitting"
        @click.prevent="submit"
      >
        {{ actionTitle }}
      </button>
    </form>
    <div v-show="isSubmitting" class="absolute z-5 inset-0 bg-base-100/60"></div>
    <div
      v-show="isSubmitting"
      role="status"
      class="absolute z-10 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
    >
      <svg
        aria-hidden="true"
        class="w-8 h-8 text-base-300 animate-spin dark:text-gray-600 fill-state-error"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'PromptInput'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import { withDefaults, defineProps, computed, onMounted, ref } from 'vue'
import { useTextareaAutosize, useElementBounding, useUrlSearchParams } from '@vueuse/core'
import { TPromptProps } from '@/domain/prompt/contracts/TPromptProps'
import { usePromptFocus } from '@/domain/prompt/composables/usePromptFocus'
import { usePrompt } from '@/domain/prompt/composables/usePrompt'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import { useRoute, useRouter } from 'vue-router'
import type { TPromptInputRemoteConfigData } from '@/domain/prompt/contracts/TPromptInputRemoteConfigData'
import { fetchOneEntry } from '@builder.io/sdk-vue'
import { useConfig } from '@/app/services/useConfig'
import { CBuilderModels } from '@/app/contracts/CBuilderModels'
import { awaitableSleep } from '@/app/support/awaitableResponse'
import { CAuthenticationRouteNames } from '@/domain/Authentication'

const minPromptHeight = 44
const maxPromptHeight = 234

const { textarea: promptInputElement, input: promptValue } = useTextareaAutosize()
const { height: promptInputElementHeight } = useElementBounding(promptInputElement)
const conditionallyApplyHideScrollBarClass = computed(() =>
  promptInputElementHeight.value < maxPromptHeight ? 'hide-scrollbar' : '',
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const props = withDefaults(defineProps<TPromptProps & { attributes?: any }>(), {
  placeholder: 'Enter your challenge',
  actionTitle: 'GO',
  autoFocusAction: false,
  tabIndex: 0,
  containerClasses: 'py-6 lg:py-8 w-full',
  remoteConfig: undefined,
  isAutoSubmitEnabled: false,
})

const {
  init: initWithPrompt,
  isFocused: isPromptInputFocused,
  doFocus,
} = usePromptFocus()
initWithPrompt(promptInputElement)

const { push: navigate } = useRouter()
const { submit, isSubmissionDisabled, isSubmitting } = usePrompt(
  promptValue,
  isPromptInputFocused,
  async (uuid, query) => {
    await navigate({
      name: CRouteNames.topic,
      params: { topicId: uuid },
      query,
    })
  },
  async (query) => {
    await navigate({
      name: CAuthenticationRouteNames.signIn,
      query: {
        ...query,
      },
    })
  },
  (promptValue: string, documentId: string) => {
    // @info nothing to do here
    return Promise.resolve()
  },
)

// @todo configure prompt length by environment variable
// @todo use zod for validation

const placeholder = ref<string>()
const actionTitle = ref<string>()
const containerClasses = ref<string>()

onMounted(async () => {
  let fetchedData: TPromptInputRemoteConfigData = null

  if (props.remoteConfig?.shouldFetch && props.remoteConfig?.searchType === 'route') {
    fetchedData = ((
      await fetchOneEntry({
        apiKey: useConfig().get().builderIO.publicApiKey,
        model: CBuilderModels.PromptInput,
        query: {
          data: {
            routeOrPath: { $eq: useRoute().name },
          },
        },
      })
    )?.data ?? null) as TPromptInputRemoteConfigData
  }

  placeholder.value =
    fetchedData === null
      ? props.placeholder
      : fetchedData.showPlaceholder
      ? fetchedData.placeholder
      : undefined

  actionTitle.value = fetchedData?.actionTitle ?? props.actionTitle
  containerClasses.value = fetchedData?.containerClasses ?? props.containerClasses

  if (props.isAutoSubmitEnabled && promptValue.value) {
    await awaitableSleep(500, false)
    doFocus()
    const queryParams = useUrlSearchParams<{ auto_apply_prompt_after_login?: string }>()
    if (
      queryParams.auto_apply_prompt_after_login &&
      Boolean(parseInt(queryParams.auto_apply_prompt_after_login))
    ) {
      await awaitableSleep(500, false)
      await submit()
    }
  }
})
</script>

<style scoped>
textarea::placeholder {
  @apply text-base-400;
}

textarea.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

textarea.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>
