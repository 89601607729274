<template>
  <RouterLink
    v-if="isVisible"
    :class="container"
    tag="a"
    :to="props.entry.attributes.path"
  >
    <article :id="slug" :class="contentContainer">
      <div :class="header">
        <img
          loading="lazy"
          :src="props.entry.attributes.image"
          :alt="`Teaser image for the '${props.entry.attributes.title}' guide published on Miljn`"
          :class="image"
          width="636"
          height="280"
        />
        <span v-if="imageBadgeText" :class="imageBadge" v-html="imageBadgeText" />
      </div>
      <section :class="sectionContent">
        <h2 :class="title" v-html="props.entry.attributes.title" />
        <div :class="footer">
          <div :class="authorContainer">
            <div
              v-for="(author, index) in authorsComputed"
              :key="author.id"
              :class="[authorClasses, index > props.maxVisibleAuthors - 1 && 'hidden']"
            >
              <img
                loading="lazy"
                :class="authorImage"
                :src="author.attributes.image"
                :alt="`Profile image of ${author.attributes.name}`"
              />
              <span :class="authorName" v-html="author.attributes.name" />
            </div>
          </div>
          <div :class="categoryLabelContainer">
            <span
              v-for="(category, index) in categoriesComputed"
              :key="category.id"
              :class="[categoryLabel, index > props.maxVisibleCategories - 1 && 'hidden']"
              v-html="category.attributes.label"
            />
          </div>
        </div>
      </section>
    </article>
  </RouterLink>
</template>

<script lang="ts">
export const componentName = 'BookDirectoryCard'
</script>

<script lang="ts" setup>
import { computed, defineOptions, type UnwrapRef } from 'vue'
import { useIsTouchDevice } from '@/app/composables/useIsTouchDevice'
import {
  type NavigationGuardNext,
  onBeforeRouteLeave,
  type RouteLocationNormalized,
  useRouter,
} from 'vue-router'
import slugify from 'slugify'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import type { TBookDirectoryEntry } from '@/domain/Book/contracts/TBookDirectoryEntry'
import type { DeepReadonly } from '@vue/reactivity'
import type { TAuthor } from '@/domain/Book/contracts/TAuthor'
import type { TCategory } from '@/domain/Book/contracts/TCategory'
import { twMerge } from 'tailwind-merge'
import { cva } from 'class-variance-authority'
import { useDocumentOrigin } from '@/domain/documents/composables/useDocumentOrigin'
import { EDocumentInteractions } from '@/domain/documents/contracts/EDocumentInteractions'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<{
    entry: DeepReadonly<UnwrapRef<TBookDirectoryEntry>>
    classes?: {
      container?: string
      contentContainer?: string
      header?: string
      image?: string
      imageBadge?: string
      sectionContent?: string
      title?: string
      footer?: string
      authorContainer?: string
      authorClasses?: string
      authorImage?: string
      authorName?: string
      categoryLabelContainer?: string
      categoryLabel?: string
    }

    imageBadgeText?: string
    maxVisibleAuthors?: number
    maxVisibleCategories?: number

    findAuthors: (entry: DeepReadonly<UnwrapRef<TBookDirectoryEntry>>) => TAuthor[]
    findCategories: (entry: DeepReadonly<UnwrapRef<TBookDirectoryEntry>>) => TCategory[]
  }>(),
  {
    classes: () => ({
      container: undefined,
      contentContainer: undefined,
      header: undefined,
      image: undefined,
      imageBadge: undefined,
      sectionContent: undefined,
      title: undefined,
      footer: undefined,
      authorContainer: undefined,
      authorClasses: undefined,
      authorImage: undefined,
      authorName: undefined,
      categoryLabelContainer: undefined,
      categoryLabel: undefined,
    }),
    imageBadgeText: 'Curated',
    maxVisibleAuthors: 3,
    maxVisibleCategories: 1,
  },
)

const { currentRoute } = useRouter()

const { isTouchDevice } = useIsTouchDevice()
const isVisible = computed(
  () => props.entry.attributes.title && props.entry.attributes.image,
)
const slug = computed(() =>
  !props.entry.attributes.path ? undefined : slugify(props.entry.attributes.path),
)
const authorsComputed = computed(() => props.findAuthors(props.entry))
const categoriesComputed = computed(() => props.findCategories(props.entry))

const container = computed(() =>
  twMerge(
    cva(
      'flex flex-col bg-base-100 rounded-2xl border border-solid shadow-lg border-base-200 overflow-hidden text-left cursor-pointer transition-transform duration-300 ease-in-out',
      {
        variants: {
          isMobile: {
            false: 'group hover:scale-105',
          },
        },
      },
    )({
      isMobile: isTouchDevice.value,
      class: [props?.classes?.container, componentName],
    }),
  ),
)

const contentContainer = computed(() =>
  twMerge(
    cva('flex flex-col grow')({
      class: props?.classes?.contentContainer,
    }),
  ),
)

const header = computed(() =>
  twMerge(
    cva('relative overflow-hidden')({
      class: props?.classes?.header,
    }),
  ),
)

const image = computed(() =>
  twMerge(
    cva(
      'w-full object-cover aspect-[2.27] group-hover:scale-110 transition-transform duration-300 ease-in-out',
    )({
      class: props?.classes?.image,
    }),
  ),
)

const imageBadge = computed(() =>
  twMerge(
    cva(
      'absolute top-0 end-0 rounded-se-2xl rounded-es-2xl text-xs font-medium bg-state-error-focus text-white py-1.5 px-4 group-hover:bg-state-error',
    )({
      class: props?.classes?.imageBadge,
    }),
  ),
)

const sectionContent = computed(() =>
  twMerge(
    cva('flex flex-col justify-between items-between px-4 grow py-3 w-full')({
      class: props?.classes?.sectionContent,
    }),
  ),
)

const title = computed(() =>
  twMerge(
    cva('text-base font-semibold leading-5 text-base-content line-clamp-4 min-h-[40px]')({
      class: props?.classes?.title,
    }),
  ),
)

const footer = computed(() =>
  twMerge(
    cva(
      'flex justify-between items-center mt-3 space-x-3 text-sm leading-5 text-base-400',
    )({
      class: props?.classes?.footer,
    }),
  ),
)

const authorContainer = computed(() =>
  twMerge(
    cva('flex max-w-[70%] shrink-0 space-x-1')({
      class: props?.classes?.authorContainer,
    }),
  ),
)

const authorClasses = computed(() =>
  twMerge(
    cva(
      'flex items-center space-x-2 overflow-hidden group-hover:scale-[1.08] transition-transform duration-300 ease-in-out',
    )({
      class: props?.classes?.authorClasses,
    }),
  ),
)

const authorImage = computed(() =>
  twMerge(
    cva('w-8 h-8 object-cover aspect-square rounded-full')({
      class: props?.classes?.authorImage,
    }),
  ),
)

const authorName = computed(() =>
  twMerge(
    cva(
      'text-sm font-medium text-base-content whitespace-nowrap overflow-hidden overflow-ellipsis',
      {
        variants: {
          isVisible: {
            false: 'hidden',
          },
        },
      },
    )({
      isVisible: (authorsComputed?.value ?? []).length === 1,
      class: props?.classes?.authorName,
    }),
  ),
)

const categoryLabelContainer = computed(() =>
  twMerge(
    cva('flex flex-row space-x-1 overflow-hidden')({
      class: props?.classes?.categoryLabelContainer,
    }),
  ),
)

const categoryLabel = computed(() =>
  twMerge(
    cva(
      'inline-block font-normal shrink overflow-hidden overflow-ellipsis whitespace-nowrap',
    )({
      class: props?.classes?.categoryLabel,
    }),
  ),
)

onBeforeRouteLeave(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    if (to.path === props.entry.attributes.path) {
      const properties = await useDocumentOrigin({ router: useRouter() }).fromSnapshot(
        {
          interaction_type: EDocumentInteractions.showedInterestInADocument,
          route: currentRoute.value.name,
          route_query: { ...currentRoute.value.query },
          route_params: { ...currentRoute.value.params },
        },
        props.entry?.attributes?.template,
      )

      captureWithUser(EUserEvents.interactedWithADocument, properties)
    }
    next()
    return
  },
)
</script>

<style scoped></style>
