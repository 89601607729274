import miljnApiBaseClientFactory from '@/app/services/miljnApiBaseClientFactory'
import { blockTypeApiEndpoint } from '@/domain/blockList/support/blockTypeApiEndpoint'
import { setPreferHeader } from '@/app/services/preferHeaderResolver'

import { IViewAnyBlockTypeResponse } from '@/domain/blockList/contracts/IViewAnyBlockTypeResponse'
import { IViewBlockTypeResponse } from '@/domain/blockList/contracts/IViewBlockTypeResponse'
import { IBlockType } from '@/domain/blockList/contracts/IBlockType'

export const viewAny = async (): Promise<IBlockType[]> => {
  const instance = miljnApiBaseClientFactory()
  const requestConfig = setPreferHeader({}, 'blockTypes')

  return (
    (
      await instance.get<IViewAnyBlockTypeResponse>(
        blockTypeApiEndpoint.viewAny,
        requestConfig,
      )
    )?.data?.data ?? []
  )
}

export const view = async (blockTypeId: string): Promise<IBlockType | undefined> => {
  const instance = miljnApiBaseClientFactory()
  const requestConfig = setPreferHeader({}, 'blockTypes')

  return (
    (
      await instance.get<IViewBlockTypeResponse>(
        blockTypeApiEndpoint.view(blockTypeId),
        requestConfig,
      )
    )?.data?.data ?? undefined
  )
}
