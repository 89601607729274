import posthog, { type CaptureOptions, type Properties } from 'posthog-js'
import { useConfig } from '@/app/services/useConfig'
import { useUserStore } from '@/app/services/useUserStore'
import { useLocalLogger } from '@/app/composables/useLocalLogger'
import { v4 as uuid } from 'uuid'
import { isEmpty } from 'lodash-es'
import { Environment } from '@/app/support/Environment'

const mockActionFactory =
  (type: string) =>
  (...args: unknown[]) => {
    // eslint-disable-next-line no-console
    console.log(`⚠️  posthog.${type}`, ...args)

    if (useConfig().get().environment !== 'production') {
      useLocalLogger().log((args[0] as string) ?? 'unknown', args[1])
    }
  }

export const usePosthog = () => {
  const fakePosthog = {
    capture: mockActionFactory('capture'),
    identify: mockActionFactory('identify'),
    reset: mockActionFactory('reset'),
    get_distinct_id: () => {
      // eslint-disable-next-line no-console
      console.log(`⚠️  posthog.get_distinct_id`)

      return undefined
    },
  }

  try {
    return useConfig().get().posthog.isEnabled && posthog ? posthog : fakePosthog
  } catch {
    return fakePosthog
  }
}

export const injectCurrentDistinctIntoHeaders = (headers: Record<string, string>) => {
  if (usePosthog().get_distinct_id() !== undefined) {
    headers['x-frontend-distinct-id'] = usePosthog().get_distinct_id() as string
  }

  return headers
}

export const captureWithUser = (
  eventName: string,
  properties?: Properties | null,
  options?: CaptureOptions,
) => {
  const { distinctId, email } = useUserStore()
  const resolvedProperties = properties ? properties : {}

  if (!isEmpty(distinctId.value)) {
    resolvedProperties['user_id'] = distinctId.value
  }

  if (!isEmpty(email.value)) {
    resolvedProperties['user_email'] = email.value
  }

  if (!resolvedProperties['uuid']) {
    resolvedProperties['uuid'] = uuid()
  }

  resolvedProperties['timestamp'] = new Date().toISOString()
  resolvedProperties['environment'] = Environment.current()

  try {
    usePosthog().capture(eventName, resolvedProperties, options)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('⚠️ posthog submit failed', eventName, resolvedProperties, e)
  }
}
