import { computed, onMounted, ref, type Ref, toRaw, watch } from 'vue'
import { useMagicKeys, useUrlSearchParams } from '@vueuse/core'
import { isEmpty } from 'lodash-es'
import type { TDocumentLoaderQueryParams } from '@/domain/prompt/contracts/TDocumentLoaderQueryParams'
import { useUserStore } from '@/app/services/useUserStore'
import { createDocument } from '@/domain/documents/services/documentClient'
import { useDocumentOrigin } from '@/domain/documents/composables/useDocumentOrigin'
import { EDocumentInteractions } from '@/domain/documents/contracts/EDocumentInteractions'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import type { RouteLocationNormalizedLoaded } from 'vue-router'
import { CDocumentOriginUnknown } from '@/domain/documents/contracts/CDocumentOriginUnknown'
import { isEmptyOrWhitespace } from '@/support/isEmptyOrWhitespace'

export const usePrompt = (
  prompt: Ref<string>,
  isPromptInputFocused: Ref<boolean>,
  redirectToSolutionAction: (
    uuid: string,
    query: TDocumentLoaderQueryParams,
  ) => Promise<void>,
  redirectToLoginFirstAction: (query: TDocumentLoaderQueryParams) => Promise<void>,
  afterRedirectCallback?: (promptValue: string, documentId: string) => Promise<void>,
  options?: {
    currentRoute?: Ref<RouteLocationNormalizedLoaded>
    applyQueryAttributesBeforeSubmit?: boolean
  },
) => {
  const _params = useUrlSearchParams<TDocumentLoaderQueryParams>('history')
  const _isSubmitting = ref(false)

  const ensureQueryParamsArePresent = (
    params: TDocumentLoaderQueryParams,
    prompt: string,
  ) => {
    params['prompt'] = prompt
    params['loading_style'] = 'skeleton'
  }
  const ensureQueryParamsAreAbsent = (params: TDocumentLoaderQueryParams) => {
    delete params['prompt']
    delete params['loading_style']
  }

  const _doSubmit = async () => {
    if ((options?.applyQueryAttributesBeforeSubmit ?? undefined) === true) {
      ensureQueryParamsArePresent(_params, prompt.value)
    }

    _isSubmitting.value = true

    const derivedPrompt = _params.prompt
    if (!derivedPrompt) {
      // eslint-disable-next-line no-console
      console.error(`title cannot be empty`)
      return
    }

    if (!useUserStore().isLoggedIn.value) {
      await redirectToLoginFirstAction({ ...toRaw(_params) })
      return
    }

    const properties = useDocumentOrigin().fromPrompt(
      {
        interaction_type: EDocumentInteractions.intendedToCreateADocumentFromPrompt,
        route: options?.currentRoute?.value?.name ?? CDocumentOriginUnknown,
        route_query: { ...(options?.currentRoute?.value?.query ?? {}) },
        route_params: { ...(options?.currentRoute?.value?.query ?? {}) },
      },
      derivedPrompt,
      undefined,
    )
    captureWithUser(EUserEvents.interactedWithADocument, properties)

    const result = await createDocument(derivedPrompt as string)
    _isSubmitting.value = false

    if (!result?.id) {
      // eslint-disable-next-line no-console
      console.error(`creating document failed for '${derivedPrompt}'`)
      return
    }

    await redirectToSolutionAction(result.id, { ...toRaw(_params) })

    if (afterRedirectCallback) {
      afterRedirectCallback(derivedPrompt, result.id)
    }
  }

  onMounted(() => {
    if (!isEmpty(_params?.prompt)) {
      prompt.value = _params.prompt as string
    }
  })

  watch(prompt, (newPromptValue) => {
    if (isEmptyOrWhitespace(newPromptValue)) {
      ensureQueryParamsAreAbsent(_params)
      return
    }

    if ((options?.applyQueryAttributesBeforeSubmit ?? undefined) === false) {
      ensureQueryParamsArePresent(_params, newPromptValue)
    }
  })

  const keys = useMagicKeys()
  const metaEnter = keys['Meta+Enter']
  watch(
    [metaEnter, isPromptInputFocused],
    async ([newMetaEnter, newIsPromptInputFocused]) => {
      if (
        newMetaEnter &&
        newIsPromptInputFocused &&
        (options?.applyQueryAttributesBeforeSubmit ?? undefined) === false
      ) {
        await _doSubmit()
      }
    },
  )

  return {
    isSubmissionDisabled: computed(() => isEmpty(prompt.value)),
    isSubmitting: computed(() => _isSubmitting.value),
    submit: _doSubmit,
  }
}
