import type { TSignInWithProviderAction } from '@/domain/Authentication/contracts/TSignInWithProviderAction'
import { Ref } from 'vue'
import { EAuthenticationProviderSignInFlowStates } from '@/domain/Authentication/contracts/EAuthenticationProviderSignInFlowStates'
import { EAuthenticationProviders } from '@/domain/Authentication/contracts/EAuthenticationProviders'
import type { TSignInContext } from '@/domain/Authentication/contracts/TSignInContext'
import type { TRouteResolver } from '@/domain/Authentication/contracts/TRouteResolver'
import type { TCurrentAuthenticationState } from '@/domain/Authentication/contracts/TCurrentAuthenticationState'
import type { TSignInOptions } from '@/domain/Authentication/contracts/TSignInOptions'
import { EMagicSignInStages } from '@/domain/Authentication/contracts/EMagicSignInStages'
import { requestAuthenticationDocumentBySecretType } from '@/domain/Authentication/services/requestAuthenticationDocumentBySecretType'
import { EAuthenticationSecretTypes } from '@/domain/Authentication/contracts/EAuthenticationSecretTypes'
import {
  captureWithUser,
  injectCurrentDistinctIntoHeaders,
} from '@/app/support/usePosthog'
import { EAuthenticationEvents } from '@/domain/Authentication/contracts/EAuthenticationEvents'
import { resolveAfterSignInRedirectTarget } from '@/domain/Authentication/support/resolveAfterSignInRedirectTarget'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'
import { useUserStore } from '@/app/services/useUserStore'
import type { RouteLocation } from 'vue-router'
import { useNavigator } from '@/app/composables/useNavigator'
import { CAuthenticationRouteNames } from '@/domain/Authentication/contracts/CAuthenticationRouteNames'
import { injectCopyIdIntoHeaders } from '@/domain/Authentication/support/injectCopyIdIntoHeaders'

export const signInWithMagicSignIn: TSignInWithProviderAction = async (
  status: Ref<EAuthenticationProviderSignInFlowStates>,
  selectedProvider: Ref<EAuthenticationProviders>,
  signInContext: Readonly<Ref<TSignInContext>>,
  routeResolver: TRouteResolver,
  reset: () => TCurrentAuthenticationState,
  options?: TSignInOptions,
): Promise<void | (RouteLocation & { href: string })> => {
  if (selectedProvider.value !== EAuthenticationProviders.MAGIC_SIGN_IN) {
    // eslint-disable-next-line
    console.error(
      `Provider '${selectedProvider}' not support, '${EAuthenticationProviders.MAGIC_SIGN_IN}' is required`,
    )
    return
  }

  if (!options?.stage) {
    throw new Error(`Required options are missing for '${selectedProvider.value}'`)
  }

  if (!Object.values(EMagicSignInStages).includes(options.stage as EMagicSignInStages)) {
    throw new Error(`Required options are missing for '${selectedProvider.value}'`)
  }

  if (options && !('secret' in options)) {
    throw new Error(`Required options are missing for '${selectedProvider.value}'`)
  }

  const nonRefSignInContext = signInContext.value
  if (options?.stage === EMagicSignInStages.REQUEST_SIGN_IN_URI) {
    try {
      const email = options?.secret as string

      const headers = injectCurrentDistinctIntoHeaders({})
      injectCopyIdIntoHeaders(headers, signInContext.value?.share_id)

      await requestAuthenticationDocumentBySecretType(
        EAuthenticationSecretTypes.MAGIC_SIGN_IN_EMAIL,
        email,
        { ...signInContext.value },
        headers,
      )

      status.value = EAuthenticationProviderSignInFlowStates.SUCCEEDED
      captureWithUser(EAuthenticationEvents.signedInSuccessfully, {
        status: status.value,
        provider: selectedProvider.value,
        stage: EMagicSignInStages.REQUEST_SIGN_IN_URI,
        signInContext: { ...signInContext.value },
      })
      // @info reset happens when
    } catch (error) {
      const errorMessage = (error as Partial<{ message?: string }>).message ?? undefined
      status.value = EAuthenticationProviderSignInFlowStates.FAILED

      const _status = status.value
      const _provider = selectedProvider.value

      const afterNavigationCallback = (): Promise<void> => {
        captureWithUser(EAuthenticationEvents.signInFailed, {
          status: _status,
          provider: _provider,
          stage: EMagicSignInStages.REQUEST_SIGN_IN_URI,
          signInContext: { ...nonRefSignInContext },
          errorMessage,
        })

        // @todo what should we do here? triggering an user notification?
        // @hint UserNotification

        reset()
        return Promise.resolve()
      }

      await useNavigator().push(
        {
          name: CAuthenticationRouteNames.signIn,
        },
        afterNavigationCallback,
      )
    }

    return
  }

  try {
    const authenticationDocument = await requestAuthenticationDocumentBySecretType(
      EAuthenticationSecretTypes.MAGIC_SIGN_IN_URI,
      options?.secret as string,
      { ...signInContext.value },
    )

    if (!authenticationDocument) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error('Did not receive JWT payload')
    }

    useAuthentication().storeJwt(authenticationDocument.attributes.payload)
    await useUserStore().refresh()
    status.value = EAuthenticationProviderSignInFlowStates.SUCCEEDED

    if (authenticationDocument.attributes.relatedCopyId) {
      nonRefSignInContext.share_id = authenticationDocument.attributes.relatedCopyId
    }

    const navigationTarget = resolveAfterSignInRedirectTarget(
      routeResolver,
      nonRefSignInContext,
      false,
    )

    const afterNavigationCallback = (): Promise<void> => {
      captureWithUser(EAuthenticationEvents.signedInSuccessfully, {
        status: status.value,
        provider: selectedProvider.value,
        stage: EMagicSignInStages.REQUEST_JWT,
        signInContext: { ...nonRefSignInContext },
      })
      reset()
      return Promise.resolve()
    }

    await useNavigator().push(navigationTarget, afterNavigationCallback)
  } catch (error) {
    const errorMessage = (error as Partial<{ message?: string }>).message ?? undefined
    status.value = EAuthenticationProviderSignInFlowStates.FAILED

    const _status = status.value
    const _provider = selectedProvider.value

    const afterNavigationCallback = (): Promise<void> => {
      captureWithUser(EAuthenticationEvents.signInFailed, {
        status: _status,
        provider: _provider,
        stage: EMagicSignInStages.REQUEST_JWT,
        signInContext: { ...nonRefSignInContext },
        errorMessage,
      })

      // @todo what should we do here? triggering an user notification?
      // @hint UserNotification

      reset()
      return Promise.resolve()
    }

    await useNavigator().push(
      {
        name: CAuthenticationRouteNames.signIn,
      },
      afterNavigationCallback,
    )
  }
}
