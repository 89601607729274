import { computed, type Ref } from 'vue'
import type { Card } from '@/types'
import { getMetaDataValue, resolveMetaDataFromCard } from '@/domain/cards/support/helper'
import { ELegacyMetadataTypes } from '@/domain/documents/contracts/ELegacyMetadataTypes'
import { isEmptyOrWhitespace } from '@/support/isEmptyOrWhitespace'

export const useDocumentTitle = (
  cardData: Ref<Card>,
  query?: Record<string, unknown> | undefined,
) => {
  return computed(() => {
    const titleMetadata = resolveMetaDataFromCard(cardData, ELegacyMetadataTypes.title)
    const titleFromMetadata = getMetaDataValue(titleMetadata) as string

    if (!isEmptyOrWhitespace(titleFromMetadata)) {
      return titleFromMetadata
    }

    if (
      query &&
      isEmptyOrWhitespace(titleFromMetadata) &&
      !isEmptyOrWhitespace(query?.prompt)
    ) {
      return query?.prompt as string
    }

    if (
      query &&
      isEmptyOrWhitespace(titleFromMetadata) &&
      !isEmptyOrWhitespace(query?.document_title)
    ) {
      return query?.document_title as string
    }

    return undefined
  })
}

export const useNodeTitle = useDocumentTitle
