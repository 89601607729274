<template>
  <ConditionalElementWrapper
    :container-classes="containerClasses"
    :wrap="props.width === 'narrow'"
  >
    <template #element>
      <RouterLink
        v-if="props.actionType === 'route' || routeResolvedFromUrl"
        v-slot="{ navigate }"
        :class="[props.width === 'narrow' ? 'w-auto' : '', elementClasses]"
        :to="routeResolvedFromUrl ? routeResolvedFromUrl : routeLocationComputed"
      >
        <button role="link" @click="[logAction(), navigate]" v-html="props.title" />
      </RouterLink>

      <RouterLink
        v-if="props.actionType === 'path-with-hash'"
        v-slot="{ navigate }"
        :class="[props.width === 'narrow' ? 'w-auto' : '', elementClasses]"
        :to="routeLocationComputed"
      >
        <button role="link" @click="navigate" v-html="props.title" />
      </RouterLink>

      <a
        v-if="props.actionType === 'url' && !routeResolvedFromUrl"
        :class="elementClasses"
        :href="hrefComputed"
        @click="logAction"
        v-html="props.title"
      />
    </template>
  </ConditionalElementWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'CallToActionButton'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import ConditionalElementWrapper from '@/app/components/ConditionalElementWrapper.vue'
import { computed, onMounted, ref } from 'vue'
import { captureWithUser } from '@/app/support/usePosthog'
import { EContentEvents } from '@/domain/Content/contracts/EContentEvents'
import { type RouteLocation, useRouter } from 'vue-router'
import { isObject, isString } from '@vueuse/core'
import { useDocumentOrigin } from '@/domain/documents/composables/useDocumentOrigin'

const props = withDefaults(
  defineProps<
    {
      interactionType?: string
      title: string
      elementClasses?: string
      mergeElementClasses?: boolean
      containerClasses?: string
      mergeContainerClasses?: boolean
      actionTarget: string | RouteLocation
      actionType: 'route' | 'url' | 'path-with-hash'
      actionHash?: string
      width?: 'narrow' | 'wide'
    } & {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      attributes?: any
    }
  >(),
  {
    width: 'narrow',
    mergeElementClasses: true,
    mergeContainerClasses: true,
  },
)

const defaultContainerClasses = ''
const containerClasses = ref<string>()

const defaultElementClasses = 'text-sm px-4 py-2 text-center py-2'
const elementClasses = ref<string>()

onMounted(() => {
  containerClasses.value = props.mergeContainerClasses
    ? `${defaultContainerClasses} ${props.containerClasses}`
    : props.containerClasses
  elementClasses.value = props.mergeElementClasses
    ? `${defaultElementClasses} ${props.elementClasses}`
    : props.elementClasses
})

const router = useRouter()
const { currentRoute, resolve: resolveRoute } = router

const logAction = async () => {
  const path = isString(props.actionTarget)
    ? new URL(props.actionTarget as string).pathname
    : props.actionTarget.path
  const properties = await useDocumentOrigin({ router: router }).fromSnapshot(
    {
      route: (currentRoute.value.name as string).toLowerCase(),
      params: { ...currentRoute.value.params },
      query: { ...currentRoute.value.query },
    },
    path,
  )
  captureWithUser(EContentEvents.clickedCtaButton, properties)
}

const routeResolvedFromUrl = computed(() => {
  if (props.actionType !== 'url') {
    return undefined
  }

  try {
    const url = new URL(props.actionTarget as string)
    return resolveRoute(url.pathname)
  } catch {
    return undefined
  }
})

const routeLocationComputed = computed(() => {
  if (props.actionType === 'route' && isObject(props.actionTarget)) {
    return props.actionTarget
  }

  if (props.actionType === 'path-with-hash' && isString(props.actionTarget)) {
    return { path: props.actionTarget, hash: props.actionHash }
  }

  return { name: props.actionTarget as string }
})

const hrefComputed = computed(() => {
  if (props.actionType === 'path-with-hash' && isString(props.actionTarget)) {
    return props.actionTarget
  }

  if (props.actionType === 'url' && isString(props.actionTarget)) {
    return props.actionTarget
  }

  return undefined
})
</script>

<style scoped></style>
