export enum EAuthenticationEvents {
  selectedProvider = 'user_selected_provider_for_sign_in',
  signedInSuccessfully = 'user_signed_in_successfully',
  signInFailed = 'user_sign_in_failed',
  selectedProviderIsNotImplemented = 'user_tried_to_sign_in_with_not_implemented_provider',
  aborted = 'user_aborted_sign_in',
  tryAnotherEmail = 'user_expressed_intention_to_try_another_email_for_sign_in_with_magic_uri_route',
  logout = 'user_logged_out',
  visitedSignInPage = 'user_visited_sign_in_page',
  forcefullyRedirectedToSignInPage = 'user_forcefully_redirected_to_sign_in_page',
}
