import { CRouteNames } from '@/app/contracts/CRouteNames'
import { applyShareLink, getSharingInformation } from '@/domain/cards/services/cardClient'
import { useNotificationsStore } from '@/domain/notifications/services/useNotificationsStore'
import { createNotification } from '@/domain/notifications/services/notificationFactory'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import type { AxiosError } from 'axios'
import { truncate } from 'lodash-es'
import { CAuthenticationRouteNames } from '@/domain/Authentication'
import { ref } from 'vue'
import type { TSharingInformation } from '@/domain/cards/contracts/TSharingInformation'
import {
  type NavigationGuardNext,
  type RouteLocationNormalized,
  type Router,
} from 'vue-router'
import { handleCaseNotFound } from '@/app/support/handleCaseNotFound'
import { handleCreateDocumentCopyGuardAction } from '@/domain/documents/support/handleCreateDocumentCopyGuardAction'
import { useDocumentOrigin } from '@/domain/documents/composables/useDocumentOrigin'
import { EDocumentInteractions } from '@/domain/documents/contracts/EDocumentInteractions'
import { CDocumentOriginUnknown } from '@/domain/documents/contracts/CDocumentOriginUnknown'
import { flare } from '@flareapp/flare-client'

const resolveDocumentTitle = (information: TSharingInformation | null) =>
  truncate(information?.data.attributes.documentTitle ?? '', {
    length: 50,
    omission: '...',
  })

export const shareTopicNavigationGuard =
  (router: Router) =>
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    const shareId = to.params?.shareId as string
    const { exposeOneNotification } = useNotificationsStore()

    const information = ref<TSharingInformation | null>(null)

    try {
      information.value = await getSharingInformation(shareId)
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 400) {
        await handleCaseNotFound(
          error as AxiosError,
          to,
          next,
          exposeOneNotification,
          shareId,
          router,
        )
        return
      }
    }

    if (
      await handleCreateDocumentCopyGuardAction(
        information,
        to,
        next,
        exposeOneNotification,
        router,
      )
    ) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let response: any = undefined
    try {
      response = await applyShareLink(shareId)
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { status, data } = ((error as AxiosError)?.response ?? {}) as any

      if (status === 404) {
        await handleCaseNotFound(
          error as AxiosError,
          to,
          next,
          exposeOneNotification,
          shareId,
          router,
        )
        return
      }

      const documentTitle = resolveDocumentTitle(
        information.value as TSharingInformation | null,
      )

      if (status && data && status === 401 && data?.errors[0]?.code === '4370cc81') {
        const notification = createNotification(
          `Please login to access "${documentTitle}"`,
          'error',
        )
        exposeOneNotification(notification, 10)

        const documentProperties = await useDocumentOrigin({
          router: router,
        }).fromSnapshot(
          {
            route: to.name,
            route_query: { ...to.query },
            route_params: { ...to.params },
            guard: 'shareTopicNavigationGuard',
            error: `Authentication is required to access case redirect to '${CAuthenticationRouteNames.signIn}'`,
            errorCode: '4370cc81',
            status: status,
          },
          to.path,
          information?.value ?? undefined,
        )

        captureWithUser(EUserEvents.receivedError, documentProperties)

        next({
          name: CAuthenticationRouteNames.signIn,
          query: {
            share_id: shareId,
            ...to.query,
          },
          replace: true,
        })
        return
      }

      if (status && data && status === 403 && data?.errors[0]?.code === 'b7964c30') {
        const notification = createNotification(
          `The link of the guide "${documentTitle}" you're trying to access is already been expired`,
          'error',
        )
        exposeOneNotification(notification, 10)

        const documentProperties = await useDocumentOrigin({
          router: router,
        }).fromSnapshot(
          {
            route: to.name,
            route_query: { ...to.query },
            route_params: { ...to.params },
            guard: 'shareTopicNavigationGuard',
            error: `Guide link already expired redirect to '${CRouteNames.home}'`,
            status,
          },
          to.path,
          information?.value ?? undefined,
        )
        captureWithUser(EUserEvents.receivedError, documentProperties)

        next({
          name: CRouteNames.home,
          replace: true,
        })
        return
      }

      const notification = createNotification(
        `The link you're trying to access is either already expired used or not found`,
        'error',
      )
      exposeOneNotification(notification, 5)

      const documentProperties = await useDocumentOrigin({ router: router }).fromSnapshot(
        {
          route: to.name,
          route_query: { ...to.query },
          route_params: { ...to.params },
          guard: 'shareTopicNavigationGuard',
          error: `Case could not be found redirect to '${CAuthenticationRouteNames.signIn}'`,
          status,
        },
        to.path,
        information?.value ?? undefined,
      )
      captureWithUser(EUserEvents.receivedError, documentProperties)

      next({
        name: CAuthenticationRouteNames.signIn,
        replace: true,
      })
      return
    }

    const applyShareLinkResponse = response
    const documentId =
      applyShareLinkResponse?.data?.relationships?.topic?.data?.id ?? null
    const isCopy = applyShareLinkResponse?.data?.attributes?.isCopy ?? null

    if (isCopy === true || documentId === null) {
      flare.report(new Error('Invalid case should not be available anymore'), {
        topicId: documentId,
        isCopy,
        responseData: response?.data ?? undefined,
      })

      next({
        name: CRouteNames.home,
        replace: true,
      })
      return
    }

    const documentProperties = useDocumentOrigin({ router: router }).fromDocument(
      {
        interaction_type: EDocumentInteractions.intendedToReceiveAccessAsCollaborator,
        route: to.name,
        route_query: { ...to.query },
        route_params: { ...to.params },
        guard: 'shareTopicNavigationGuard',
      },
      {
        id:
          information.value?.data?.attributes?.sourceDocumentId ?? CDocumentOriginUnknown,
        title:
          information.value?.data?.attributes?.documentTitle ?? CDocumentOriginUnknown,
      },
    )
    captureWithUser(EUserEvents.interactedWithADocument, documentProperties)

    const notification = createNotification(
      `Added you as collaborator to "${
        information.value?.data.attributes.documentTitle ?? 'unknown'
      }"`,
      'info',
    )
    exposeOneNotification(notification, 5)

    next({
      name: CRouteNames.topic,
      replace: true,
      params: {
        topicId: documentId,
      },
    })
  }
