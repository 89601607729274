import type { ENodeInteractions } from '@/app/contracts/ENodeInteractions'
import { CardDataProps } from '@/domain/cards/support/interfaces'
import { ENodeMovementTypes } from '@/app/contracts/ENodeMovementTypes'
import { ENodeLevels } from '@/app/contracts/ENodeLevels'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import { flare } from '@flareapp/flare-client'
import { useDocumentOrigin } from '@/domain/documents/composables/useDocumentOrigin'
import { CDocumentOriginUnknown } from '@/domain/documents/contracts/CDocumentOriginUnknown'
import router from '@/app/services/router'

const _intendedToMoveANode = (
  interactionType: ENodeInteractions,
  document: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  context: CardDataProps[],
  movementTypes: ENodeMovementTypes,
  nodeLevels: ENodeLevels,
) => {
  try {
    const nodeIdsAsJson = JSON.stringify(context.map((m) => m.id))

    const nodeTitlesAsJson = JSON.stringify(
      context.map(
        (m) =>
          m?.relationships?.metadata?.data.filter(
            (f) =>
              f?.relationships?.metadataType?.data?.id ===
              '2b754052-a2ae-430f-afb3-60ab0a242045',
          )[0]?.attributes?.payload?.value ?? null,
      ),
    )

    const properties = {
      interaction_type: interactionType,
      node_id: `{"data": ${nodeIdsAsJson}}`,
      node_title: `{"data": ${nodeTitlesAsJson}}`,
      node_movement_type: movementTypes,
      node_level: nodeLevels,
      node_parent_id:
        (context[0]?.relationships?.parent?.data as { id?: string })?.id ?? null,
    }

    captureWithUser(
      EUserEvents.interactedWithANode,
      useDocumentOrigin().fromDocument(properties, document),
    )
  } catch (e) {
    flare.report(e as Error, {
      message: `posthog submit failed ${EUserEvents.interactedWithANode} > ${interactionType} event`,
      event: EUserEvents.interactedWithANode,
      interactionType: interactionType,
    })
  }
}

const _intendedTo = (
  interactionType: ENodeInteractions,
  document: any,
  nodeId: string,
  nodeLevel?: ENodeLevels,
  node_index?: number,
) => {
  const properties = {
    interaction_type: interactionType,
    node_id: nodeId,
    node_level: nodeLevel ?? CDocumentOriginUnknown,
    node_index: node_index ?? CDocumentOriginUnknown,
    route: router?.currentRoute.value.name ?? CDocumentOriginUnknown,
    route_query: { ...(router?.currentRoute.value.query ?? {}) },
    route_params: { ...(router?.currentRoute.value.params ?? {}) },
  }

  captureWithUser(
    EUserEvents.interactedWithANode,
    useDocumentOrigin().fromDocument(properties, document),
  )
}

export const useCaptureNodeInteractions = () => {
  return {
    intendedToMoveANode: _intendedToMoveANode,
    intendedTo: _intendedTo,
  }
}
