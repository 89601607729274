export enum EUserEvents {
  visited = 'user_visited',
  triedToVisit = 'user_tried_to_visit',
  receivedError = 'user_received_error',

  resetUserData = 'user_reset_user_data', // @todo should be moved to `User` domain

  clicked = 'user_clicked',
  triggeredCta = 'user_triggered_cta',

  interactedWithADocument = 'user_interacted_with_a_document',
  interactedWithANode = 'user_interacted_with_a_node',
  interactedWithADocumentsContent = 'interacted_with_a_documents_content',
  interactedWithANodesContent = 'interacted_with_a_nodes_content',
}
