import { isEmpty } from 'lodash-es'
import { ELegacyMetadataTypes } from '@/domain/documents/contracts/ELegacyMetadataTypes'
import type { TValueMetadata } from '@/domain/documents/contracts/TValueMetadata'
import type { TPromptMetadata } from '@/domain/documents/contracts/TPromptMetadata'
import type { TSnapshotMetadata } from '@/domain/documents/contracts/TSnapshotMetadata'

const resolver = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  document: any,
  metadataType: ELegacyMetadataTypes,
): TValueMetadata | TPromptMetadata | TSnapshotMetadata | undefined => {
  const result =
    document?.relationships?.metadata?.data?.filter(
      (f) => f?.relationships?.metadataType?.data?.id === metadataType,
    )[0]?.attributes?.payload ?? undefined

  if (!result) {
    return undefined
  }

  if ('prompt' in result && 'status' in result && result['prompt'] !== null) {
    return result as TPromptMetadata
  }

  if ('value' in result && result['value'] !== isEmpty(result.value)) {
    return result as TValueMetadata
  }

  if ('sourceId' in result && result['sourceId'] !== isEmpty(result.sourceId)) {
    return result as TSnapshotMetadata
  }

  return undefined
}

export const useMetadata = (document: object) => {
  return {
    title: () =>
      resolver(document, ELegacyMetadataTypes.title) as TValueMetadata | undefined,
    state: () =>
      resolver(document, ELegacyMetadataTypes.state) as TValueMetadata | undefined,
    documentFromSnapshot: () =>
      resolver(document, ELegacyMetadataTypes.documentFromSnapshot) as
        | TSnapshotMetadata
        | undefined,
    documentFromPrompt: () =>
      resolver(document, ELegacyMetadataTypes.documentFromPrompt) as
        | TPromptMetadata
        | undefined,
    explainANode: () =>
      resolver(document, ELegacyMetadataTypes.explainANode) as
        | TPromptMetadata
        | undefined,
    detailANodeWithAdditionalSteps: () =>
      resolver(document, ELegacyMetadataTypes.detailANodeWithAdditionalSteps) as
        | TPromptMetadata
        | undefined,
  }
}
