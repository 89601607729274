export enum EDocumentInteractions {
  openedADocument = 'opened_a_document',
  closedADocument = 'closed_a_document',

  intendedToOpenADocument = 'intended_to_open_a_document',
  showedInterestInADocument = 'showed_interest_for_a_document',

  intendedToCreateADocument = 'intended_to_create_a_document',
  intendedToCreateADocumentFromPrompt = 'intended_to_create_a_document_from_prompt',
  intendedToCreateADocumentFromSnapshot = 'intended_to_create_a_document_from_snapshot',
  intendedToReceiveAccessAsCollaborator = 'intended_to_receive_access_as_collaborator',

  intendedToShareADocumentSnapshot = 'intended_to_share_a_document_snapshot',
  intendedToProvideAccessAsCollaboratorToADocument = 'intended_to_provide_access_as_collaborator_to_a_document',

  expandedADocument = 'expanded_a_document',
  collapsedADocument = 'collapsed_a_document',

  intendedToDeleteADocument = 'intended_to_delete_a_document',

  intendedToUpdateADocumentTitle = 'intended_to_update_a_document_title',
  intendedToUpdateADocumentStatus = 'intended_to_update_a_document_status',

  intendedToExtendADocumentWithAi = 'intended_to_extend_a_document_with_ai',
  // @info intendedToExtendADocumentWithAdditionalSteps –– not relevant
  intendedToExplainADocument = 'intended_to_explain_a_document',

  // @todo intendedToRestoredADocument
}
