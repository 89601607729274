import type { RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/CallToActionButton.vue'

export const CallToActionButtonComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'CallToActionButton',
  friendlyName: 'CTA Button',
  noWrap: !shouldWrap,
  canHaveChildren: false,
  inputs: [
    {
      name: 'identifier',
      type: 'string',
      required: true,
      defaultValue: 'url',
    },
    {
      name: 'title',
      type: 'string',
    },
    {
      friendlyName: 'Element Classes',
      name: 'elementClasses',
      type: 'string',
    },
    {
      friendlyName: 'Merge Element Classes',
      name: 'mergeElementClasses',
      type: 'boolean',
      defaultValue: true,
    },
    {
      friendlyName: 'Container Classes',
      name: 'containerClasses',
      type: 'string',
    },
    {
      friendlyName: 'Merge Container Classes',
      name: 'mergeContainerClasses',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'actionTarget',
      type: 'string',
    },
    {
      friendlyName: 'Type',
      name: 'actionType',
      type: 'string',
      enum: ['route', 'url', 'path-with-hash'],
      defaultValue: 'url',
    },
    {
      friendlyName: 'Hash',
      name: 'actionHash',
      type: 'string',
    },
    {
      name: 'width',
      type: 'string',
      enum: ['narrow', 'wide'],
      defaultValue: 'wide',
    },
  ],
})
