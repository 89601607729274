import { useRestoreHistory } from '@/app/services/useRestoreHistory'
import { defineStore } from 'pinia'
import { Card } from '@/types'
import {
  createDocument,
  deleteCardById as deleteTopicById,
  getUserTopics,
  restoreCardById,
} from '@/domain/cards/services/cardClient'

import { log } from '@/app/services/errorService'
import { orderBy } from 'lodash-es'
import { resolveMetaDataFromCard } from '@/domain/cards/support/helper'
import { metaDataTypes } from './metaDataClient'
import { useAsyncTopicCopyJobUpdater } from './useAsyncTopicCopyJobUpdater'
import { captureWithUser } from '@/app/support/usePosthog'
import { EDocumentInteractions } from '@/domain/documents/contracts/EDocumentInteractions'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import { useDocumentOrigin } from '@/domain/documents/composables/useDocumentOrigin'
import { CDocumentOriginUnknown } from '@/domain/documents/contracts/CDocumentOriginUnknown'
import { v4 as uuid } from 'uuid'
import type { Ref } from 'vue'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

type TopicStoreState = {
  topics: Card[]
  isCreatingState: boolean
  sortBy: SortOptions
  order: 'asc' | 'desc'
}

export enum SortOptions {
  RecentlyCreated = 'Date created',
  RecentlyModified = 'Date modified',
  Alphabetical = 'Alphabetical',
}

export const useTopicsStore = defineStore('useTopicsStore', {
  state: () =>
    ({
      topics: [],
      isCreatingState: false,
      sortBy: SortOptions.RecentlyCreated,
      order: 'desc',
    }) as TopicStoreState,
  getters: {
    isCreating(state) {
      return state.isCreatingState
    },
    sortedTopics(state) {
      const sortTopics = (topic) => {
        if (state.sortBy === SortOptions.Alphabetical) {
          return resolveMetaDataFromCard(
            topic,
            metaDataTypes.titleId,
          )?.attributes?.payload?.value.toLowerCase()
        } else if (state.sortBy === SortOptions.RecentlyCreated) {
          return new Date(topic?.attributes?.createdAt)
        } else if (state.sortBy === SortOptions.RecentlyModified) {
          return new Date(
            resolveMetaDataFromCard(topic, metaDataTypes.titleId)?.attributes?.changed,
          )
        }
      }

      return orderBy(state.topics, [sortTopics], [state.order])
    },
  },
  actions: {
    async refreshTopics() {
      await this.findAllTopics()
    },
    async findAllTopics(
      options: { useAsyncTopicUpdater: boolean } = { useAsyncTopicUpdater: true },
    ) {
      try {
        this.topics = (await getUserTopics())?.data
        if (options?.useAsyncTopicUpdater) {
          useAsyncTopicCopyJobUpdater().watchJobs()
        }
      } catch (error) {
        log(error)
      }
    },
    async addNewTopic(
      documentTitle: string,
      performNavigation: (topicId?: string) => Promise<void>,
      enableAiSupport = false,
      currentRoute?: Ref<RouteLocationNormalizedLoaded>,
    ) {
      try {
        this.isCreatingState = true

        const newDocumentId = uuid()
        const properties = useDocumentOrigin().fromDocument(
          {
            interaction_type: EDocumentInteractions.intendedToCreateADocument,
            route: currentRoute?.value?.name ?? CDocumentOriginUnknown,
            route_query: { ...(currentRoute?.value?.query ?? {}) },
            route_params: { ...(currentRoute?.value?.query ?? {}) },
          },
          {
            id: newDocumentId,
            title: documentTitle,
          },
        )
        captureWithUser(EUserEvents.interactedWithADocument, properties)

        const createdDocument = (
          await createDocument(newDocumentId, documentTitle, enableAiSupport)
        )?.data
        this.isCreatingState = false
        this.topics.push(createdDocument)

        if (performNavigation) {
          performNavigation(createdDocument.id)
        }
      } catch (error) {
        log(error)
      }
    },
    async removeTopic(topicId) {
      await this.findTopicsIfEmpty()
      const topicIndex = this.topics.findIndex((t) => t.id === topicId)
      if (topicIndex < 0) {
        // eslint-disable-next-line no-console
        log('Could not find topic by id', { topicId })
        return
      }
      this.topics.splice(topicIndex, 1)

      try {
        await deleteTopicById(topicId)
      } catch (error) {
        log(error)
      }
    },
    async restoreLastTopic() {
      const { lastDeleted } = useRestoreHistory()
      const { id } = lastDeleted.value
      await restoreCardById(id)
      await this.findAllTopics()
    },
    async findTopicsIfEmpty(options?: { useAsyncTopicUpdater: boolean }) {
      if (this.topics.length) {
        return
      }
      await this.findAllTopics(options)
    },
  },
})
