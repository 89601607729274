import { defineStore } from 'pinia'
import { Card } from '@/types'
import {
  createCard,
  deleteCardById,
  getChildrenData,
  moveCardBetweenColumns,
  openTopicById,
  restoreCardById,
  updateCardOrder,
} from '@/domain/cards/services/cardClient'
import { log } from '@/app/services/errorService'
import { mergeMetaDataWithCardData } from '@/domain/cards/support/helper'
import { v4 as uuid } from 'uuid'
import { useRestoreHistory } from '@/app/services/useRestoreHistory'
import { nextTick, ref, toRaw } from 'vue'
import { useUserLocationStore } from '@/app/services/useUserLocationStore'
import { useCardNavigation } from '@/domain/cardExpanded/services/useCardNavigation'
import { useTimeoutFn } from '@vueuse/core'
import { flare } from '@flareapp/flare-client'
import { useCaptureNodeInteractions } from '@/domain/cards/composables/useCaptureNodeInteractions'
import { ENodeInteractions } from '@/app/contracts/ENodeInteractions'
import { ENodeLevels } from '@/app/contracts/ENodeLevels'

type CardsStoreState = {
  isReadOnly: boolean
  topic: Card | null
  parents: Card[]
  children: {
    [key: string]: Card[]
  }
  creatingCardsDisabled: boolean
  activeCardHistory: Array<string>
  newCreatedCardId: string
  cardError: string
  cardIsNotFound: boolean
}

export const isParent = (topic: Card, parents: Card, cardId: string): boolean => {
  if (topic?.id === cardId ?? false) {
    return true
  }

  return (
    parents.find(
      (card: {
        id: string
        attributes?: {
          cardType?: string
        }
      }) => card.id === cardId,
    )?.attriubutes?.cardType === 'topic' ?? false
  )
}

export const resolveCardInsertIndex = (
  cards: Card[],
  previousCardId?: string,
): number => {
  const defaultInsertIndex = cards?.length ?? 0
  let insertIndex: undefined | number = undefined

  if (previousCardId) {
    insertIndex = cards?.findIndex((child) => child.id === previousCardId)
    insertIndex = insertIndex === -1 ? defaultInsertIndex : insertIndex + 1
  }

  return insertIndex ?? defaultInsertIndex
}

const _cancelParentsImport = ref<boolean>(false)
const _cancelChildrenImport = ref<boolean>(false)

async function addSkeletonChildren(
  parentId,
  storeChildren,
  skeletonChildren: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
  delay = 200,
) {
  storeChildren[parentId] = []
  for (const child of skeletonChildren) {
    if (_cancelChildrenImport.value) {
      _cancelChildrenImport.value = false
      return
    }
    storeChildren[parentId].push(child)
    await new Promise((resolve) => useTimeoutFn(resolve, delay))
  }
}

async function addSkeletonItems(
  storeParents,
  storeChildren,
  skeletonParents: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
  skeletonChildren: object,
  delay = 200,
) {
  for (const parent of skeletonParents) {
    if (_cancelParentsImport.value) {
      _cancelParentsImport.value = false
      return
    }
    await new Promise((resolve) => useTimeoutFn(resolve, delay))
    storeParents.push(parent)
    await addSkeletonChildren(
      parent.id,
      storeChildren,
      skeletonChildren[parent.id],
      delay,
    )
  }
}

export const useCardsStore = defineStore('useCardsStore', {
  state: () =>
    ({
      isReadOnly: false,
      topic: {} as Card,
      parents: [],
      children: {},
      creatingCardsDisabled: false,
      cardTitleIsBeingEdited: false,
      activeCardHistory: [],
      newCreatedCardId: '',
      cardError: '',
      cardIsNotFound: false,
    }) as CardsStoreState,
  getters: {
    activeCardId(state) {
      return state.activeCardHistory[state.activeCardHistory.length - 1]
    },
    exposedParents(state) {
      const { viewId } = useUserLocationStore()
      if (viewId.value.length) {
        return state.children[viewId.value] ?? []
      }
      return state.parents
    },
    allCards(state) {
      const values = [state.topic, ...state.parents]
      const children = Object.values(state.children)
      if (!children.length) {
        return values
      }
      Object.values(state.children).map((inner) => values.push(...inner))
      return values
    },
  },
  actions: {
    async skeletonDataImport() {
      const skeletonParents = (await import('@/domain/cards/data/skeleton-parents.json'))
        .default
      const skeletonChildren = (
        await import('@/domain/cards/data/skeleton-children.json')
      ).default
      await addSkeletonItems(
        this.parents,
        this.children,
        skeletonParents,
        skeletonChildren,
        100,
      )
    },
    skeletonDataImportReset() {
      this.skeletonDataImportCancel()
      this.parents = []
      this.children = {}
      _cancelParentsImport.value = false
      _cancelChildrenImport.value = false
    },
    skeletonDataImportCancel() {
      _cancelParentsImport.value = true
      _cancelChildrenImport.value = true
    },
    async findAllParentsAndChildren(topicId: string, forcefulFindAll = false) {
      if (topicId === this.topic.id && !forcefulFindAll) {
        // Do not fetch same topic twice if it's unnecessary
        return
      }
      try {
        const topicData = await openTopicById(topicId)

        this.topic = topicData.data.topic
        this.parents = topicData.data.parents
        this.children = topicData.data.children

        useCardNavigation().init()
      } catch (e) {
        this.cardError = (e as Error).message
        this.cardIsNotFound = true
        log(e)
      }
    },
    async createParentCard(previousSiblingNodeId?: string, newNodeId?: string) {
      let insertNodeAtIndex = this.parents.length
      newNodeId = newNodeId ?? uuid()

      if (previousSiblingNodeId) {
        const siblingNodeIndex = this.parents.findIndex(
          (parent) => parent.id === previousSiblingNodeId,
        )
        insertNodeAtIndex =
          siblingNodeIndex === -1 ? this.parents.length : siblingNodeIndex + 1
      }

      useCaptureNodeInteractions().intendedTo(
        ENodeInteractions.intendedToCreateANode,
        this.topic,
        newNodeId,
        ENodeLevels.parent,
        insertNodeAtIndex,
      )

      try {
        this.newCreatedCardId = newNodeId
        const parentCardWithMetaData = await createCard(
          this.topic.id,
          this.newCreatedCardId,
        )

        const parentCardData = mergeMetaDataWithCardData(
          parentCardWithMetaData.included,
          [parentCardWithMetaData.data],
        ).data[0]
        const lengthOfTopicChildrenBeforeMerge =
          this.topic.relationships.children.data.length

        this.topic.relationships.children.data.splice(insertNodeAtIndex, 0, {
          id: parentCardData.id,
          type: 'cards',
        })
        this.parents.splice(insertNodeAtIndex, 0, parentCardData)

        if (insertNodeAtIndex !== lengthOfTopicChildrenBeforeMerge) {
          await updateCardOrder(
            this.topic.id,
            'topic',
            this.topic.relationships.children.data,
          )
        }

        useCardNavigation().addIdsOfChildrenIfNotExist(this.topic)
      } catch (e) {
        log(e)
      }
    },
    async updateOrder(parentCard: Card, cards: Card[]) {
      const isParentOrder = parentCard.attributes.cardType === 'topic'
      if (isParentOrder) {
        await this.updateParentsOrder(cards)
        return
      }

      await this.updateChildrenOrder(parentCard.id, cards)
    },
    async createChildCard(
      parentNodeId: string,
      previousSiblingNodeId?: string,
      newNodeId?: string,
    ) {
      const siblingsAreParents = isParent(this.topic, this.parents, parentNodeId)
      const siblings = siblingsAreParents ? this.parents : this.children[parentNodeId]
      const insertNodeAtIndex = resolveCardInsertIndex(siblings, previousSiblingNodeId)

      newNodeId = newNodeId ?? uuid()

      if (siblingsAreParents) {
        flare.report(new Error('Unusual situation occured when creating child nodes'), {
          parentNodeId,
          previousSiblingNodeId,
          newNodeId,
        })

        await this.createParentCard(parentNodeId)
        return
      }

      useCaptureNodeInteractions().intendedTo(
        ENodeInteractions.intendedToCreateANode,
        this.topic,
        newNodeId,
        ENodeLevels.parentOrChild,
        insertNodeAtIndex,
      )

      try {
        this.newCreatedCardId = newNodeId
        const childCardWithMetaData = await createCard(
          parentNodeId,
          this.newCreatedCardId,
        )

        const childCardData = mergeMetaDataWithCardData(childCardWithMetaData.included, [
          childCardWithMetaData.data,
        ]).data[0]

        const parent = this.getCardParent(parentNodeId) ?? this.topic
        const lengthOfParentChildrenBeforeMerge =
          parent?.relationships?.children?.data?.length ?? 0

        parent.relationships.children.data.splice(insertNodeAtIndex, 0, {
          id: childCardData.id,
          type: 'cards',
        })
        if (!this.children[parentNodeId]) {
          this.children[parentNodeId] = []
        }
        this.children[parentNodeId].splice(insertNodeAtIndex, 0, childCardData)

        if (insertNodeAtIndex !== lengthOfParentChildrenBeforeMerge) {
          await updateCardOrder(
            this.topic.id,
            'card',
            this.topic.relationships.children.data,
          )
        }

        useCardNavigation().addIdsOfChildrenIfNotExist(parent)
      } catch (e) {
        log(e)
      }
    },
    async updateParentsOrder(parents: Card[]) {
      try {
        this.parents = [...parents]

        const updatedParentOrder = this.parents.map((parent) => ({
          id: parent.id,
          type: 'cards',
        }))

        this.topic.relationships.children.data = [...updatedParentOrder]

        await updateCardOrder(
          this.topic.id,
          'topic',
          this.topic.relationships.children.data,
        )
      } catch (e) {
        log(e)
      }
    },
    async updateChildrenOrder(parentCardId: string, children: Card[]) {
      try {
        this.children[parentCardId] = [...children]

        const updatedChildOrder = children.map((child) => ({
          id: child.id,
          type: 'cards',
        }))

        const parent = this.getCardParent(parentCardId)
        parent.relationships.children.data = [...updatedChildOrder]

        await updateCardOrder(parentCardId, 'card', parent.relationships.children.data)
      } catch (e) {
        log(e)
      }
    },
    async deleteParentCard(cardId: string) {
      const parentIndex = this.parents.findIndex((parent) => parent.id === cardId)
      this.parents.splice(parentIndex, 1)
      const indexOfParentInTopicChildren = (
        this.topic?.relationships?.children?.data ?? []
      ).findIndex((parentAsTopicChild) => parentAsTopicChild.id === cardId)
      if (indexOfParentInTopicChildren >= 0) {
        this.topic.relationships.children.data.splice(indexOfParentInTopicChildren, 1)
      }

      try {
        await deleteCardById(cardId)

        useCardNavigation().deleteIdFromArray(cardId)
      } catch (e) {
        log(e)
      }
    },
    async deleteChildrenCard(parentCardId: string, card: Card) {
      const childIndex = this.children[parentCardId].findIndex(
        (child) => child.id === card.id,
      )
      this.children[parentCardId].splice(childIndex, 1)
      const parent = this.findCardById(parentCardId)
      parent.relationships.children.data = parent.relationships.children.data.filter(
        (child) => child.id !== card.id,
      )

      try {
        await deleteCardById(card.id)

        useCardNavigation().deleteIdFromArray(card.id)
      } catch (e) {
        log(e)
      }
    },
    restoreChildCard() {
      const { lastDeleted } = useRestoreHistory()
      const parentId = lastDeleted.value.value.relationships.parent.data.id
      const parent = this.getCardParent(parentId)
      const oldCardOrder = parent.relationships.children.data
      const restoredCardIndex = oldCardOrder.findIndex(
        (data) => data.id === lastDeleted.value.id,
      )
      this.children[parentId].splice(restoredCardIndex, 0, toRaw(lastDeleted.value.value))

      useCardNavigation().addIdsOfChildrenIfNotExist(parent)
    },
    restoreParentCard() {
      const { lastDeleted } = useRestoreHistory()
      const oldCardOrder = this.topic.relationships.children.data
      const restoredCardIndex = oldCardOrder.findIndex(
        (data) => data.id === lastDeleted.value.id,
      )
      this.parents.splice(restoredCardIndex, 0, toRaw(lastDeleted.value.value))

      useCardNavigation().addIdsOfChildrenIfNotExist(this.topic)
    },
    async restoreLastCard() {
      const { lastDeleted } = useRestoreHistory()
      const { id, type } = lastDeleted.value
      await restoreCardById(id)
      if (type === 'child') {
        this.restoreChildCard()
      } else if (type === 'parent') {
        this.restoreParentCard()
      }
    },
    pushCardToActiveCardHistory(cardId) {
      if (this.activeCardHistory[this.activeCardHistory.length - 1] !== cardId) {
        this.activeCardHistory.push(cardId)
      }
    },
    popCardFromActiveCardHistory() {
      this.activeCardHistory.pop()
    },
    async fetchChildrenOf(card: any) {
      // eslint-disable-line @typescript-eslint/no-explicit-any
      if (this.children[card.id]) return
      this.children[card.id] = (await getChildrenData(card)) ?? []
    },
    async fetchChildrenOfCurrentContext() {
      await Promise.all(this.exposedParents.map((data) => this.fetchChildrenOf(data)))
    },
    getCardParent(parentCardId: string) {
      return this.findCardById(parentCardId)
    },
    findCardById(cardId: string) {
      return this.allCards.find((child) => child.id === cardId)
    },
    findCardEnumeration(cardId: string) {
      if (cardId === this.topic.id) {
        return ''
      }
      const card = this.findCardById(cardId)
      let parentCard = this.findCardById(card?.relationships?.parent?.data?.id)
      const enumeration: any[] = [] // eslint-disable-line @typescript-eslint/no-explicit-any
      while (cardId !== this.topic.id) {
        enumeration.unshift(
          parentCard?.relationships?.children?.data.findIndex(
            (child: { id: string }) => child.id === cardId,
          ) + 1,
        )
        cardId = parentCard?.id
        parentCard = this.findCardById(parentCard?.relationships?.parent?.data?.id)
      }
      return enumeration.join('.')
    },
    async fetchUntilContextId(id: string) {
      const findAllCardsBelongsToTopic = async () => {
        const childrenOnly = this.allCards.filter(
          (card) =>
            card.attributes.cardType !== 'topic' &&
            card.relationships?.parent?.data?.id !== this.topic.id &&
            !(card.id in this.children),
        )
        await Promise.all(childrenOnly.map((data) => this.fetchChildrenOf(data)))
      }
      await findAllCardsBelongsToTopic()

      while (!this.findCardById(id)) {
        await findAllCardsBelongsToTopic()
      }
    },

    async moveCardToTheNextColumn(card: Card) {
      await this.moveCardBetweenColumns(card, 'right')
    },
    async moveCardToThePreviousColumn(card: Card) {
      await this.moveCardBetweenColumns(card, 'left')
    },
    async moveCardBetweenColumns(card: Card, direction: 'left' | 'right') {
      const cardId = card.id
      const parentCardId = card?.relationships?.parent?.data.id
      const parent = this.findCardById(parentCardId)
      const parentOfParent = this.findCardById(parent?.relationships?.parent?.data.id)
      const filteredArrayOfParentIds: string[] =
        parentOfParent?.relationships?.children?.data.map(
          (data: { id: string }) => data.id,
        )
      const indexOfParentCardId = filteredArrayOfParentIds.indexOf(parentCardId)
      const resultingParentIndex =
        direction === 'right'
          ? (indexOfParentCardId + 1) % filteredArrayOfParentIds.length
          : indexOfParentCardId - 1
      const targetParentId = filteredArrayOfParentIds.at(resultingParentIndex)
      if (!targetParentId) {
        return
      }
      const resultingColumnParentCard = this.findCardById(targetParentId)

      // Remove card from the current parent
      parent.relationships.children.data = parent.relationships.children.data.filter(
        (child: { id: string }) => child.id !== cardId,
      )
      const childIndex = this.children[parentCardId].findIndex(
        (child) => child.id === cardId,
      )
      const cardForReference = this.children[parentCardId][childIndex]
      this.children[parentCardId].splice(childIndex, 1)

      // Add card to the resulting column parent
      cardForReference.relationships.parent.data.id = targetParentId
      resultingColumnParentCard.relationships.children.data.push({
        type: 'card',
        id: cardId,
      })

      if (!this.children[targetParentId]) {
        this.children[targetParentId] = []
      }
      this.children[targetParentId].push(cardForReference)

      useCardNavigation().deleteIdFromArray(cardId)
      useCardNavigation().addIdsOfChildrenIfNotExist(resultingColumnParentCard)

      // Scroll to the added card
      await nextTick()
      const resultingColumnScrollContainer = document.querySelector(
        `[data-card-id="${targetParentId}"]+div.custom-scrollbar`,
      )
      resultingColumnScrollContainer?.scrollIntoView({
        block: 'end',
      })
      resultingColumnScrollContainer?.scroll({
        top: resultingColumnScrollContainer?.scrollHeight,
      })

      await moveCardBetweenColumns(cardId, targetParentId)
    },
  },
})
