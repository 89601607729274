import { defineGlobalStore } from '@/app/support/defineGlobalStore'
import { createGlobalState } from '@vueuse/core'
import type { Ref } from 'vue'
import { readonly, ref } from 'vue'
import { log } from '@/app/services/errorService'
import { viewAny } from '@/domain/blockList/services/blockTypeClient'
import { IBlockType } from '@/domain/blockList/contracts/IBlockType'
import { ITheBlock } from '@/domain/blockList/contracts/ITheBlock'

export const defaultBaseBlockType: IBlockType = {
  id: '694b10c6-2f99-4b1d-aafd-5c54900b0cf8',
  type: 'blockTypes',
  attributes: {
    title: 'Text',
    description: 'Start writing your plain text.',
    componentName: 'TextBlock',
    order: 10,
    options: {},
  },
}

export const useBlockTypeStore = defineGlobalStore(
  'blockTypeStore',
  createGlobalState(() => {
    // state
    const _blockTypes: Ref<IBlockType[]> = ref([])

    // actions
    const fetchAll = async () => {
      let blockTypes: IBlockType[] = []

      try {
        blockTypes = await viewAny()
      } catch (error) {
        log(error)
      }

      _blockTypes.value = [...blockTypes]
    }

    const ensureBlockTypesAreFetched = async () => {
      if (_blockTypes.value.length === 0) {
        await fetchAll()
      }

      return _blockTypes
    }

    return {
      blockTypes: readonly(_blockTypes),
      fetchAll,
      ensureBlockTypesAreFetched,
      findById: (id: string) =>
        _blockTypes.value.find((blockType: IBlockType) => blockType.id === id) ??
        defaultBaseBlockType,
      findByBlock: (block?: ITheBlock) => {
        if (!block) {
          return defaultBaseBlockType
        }

        return (
          _blockTypes.value.find(
            (blockType: IBlockType) => blockType.id === block.attributes.typeId,
          ) ?? defaultBaseBlockType
        )
      },
      findByComponentNameOr: (
        componentName: string,
        customBlockType?: IBlockType | undefined,
      ) =>
        _blockTypes.value.find(
          (blockType) => blockType.attributes.componentName === componentName,
        ) ??
        customBlockType ??
        defaultBaseBlockType,
    }
  }),
)
