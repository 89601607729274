import { defineGlobalStore } from '@/app/support/defineGlobalStore'
import { createGlobalState } from '@vueuse/core'
import { ITheBlock } from '@/domain/blockList/contracts/ITheBlock'
import { computed, reactive, ref, ShallowRef, shallowRef, triggerRef } from 'vue'
import { createShallowReactive } from '@/app/services/createShallowReactive'
import {
  create as submitCreateBlockIntention,
  remove as submitRemoveBlockIntention,
  reorder as submitReorderBlocksIntention,
  restore as submitRestoreBlockIntention,
  update as submitUpdateBlockIntention,
  viewAny,
} from '@/domain/blockList/services/theBlockClient'
import { useComputedSortedLinkedItemList } from '@/domain/LinkedList/services/useComputedSortedLinkedItemList'
import { toSortedFlattenedLinkedItemList } from '@/domain/LinkedList/support/toSortedFlattenedLinkedItemList'
import { applyOrderToFlattenedLinkableItem } from '@/domain/LinkedList/support/applyOrderToFlattenedLinkableItem'
import { flare } from '@flareapp/flare-client'
import { difference } from 'lodash-es'
import { IShallowTheBlock } from '@/domain/blockList/contracts/IShallowTheBlock'
import {
  IBlockListItemMenuEntryAction,
  TCreateNewBlockAction,
  TFetchAllBlocksAction,
  TFindBlockAction,
  TRemoveBlockAction,
  TRestoreBlockAction,
  TUpdateBlockAction,
} from '@/domain/blockList/contracts/TBlockActions'
import { useRestoreHistory } from '@/app/services/useRestoreHistory'
import { useBlockPayloadSchemaGuard } from '@/domain/blockList/services/useBlockPayloadSchemaGuard'
import router from '@/app/services/router'
import { ENodeContentInteractions } from '@/app/contracts/ENodeContentInteractions'
import { ENodeLevels } from '@/app/contracts/ENodeLevels'
import { CDocumentOriginUnknown } from '@/domain/documents/contracts/CDocumentOriginUnknown'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import { useDocumentOrigin } from '@/domain/documents/composables/useDocumentOrigin'
import { useCardsStore } from '@/domain/cards/services/useCardsStore'
import { storeToRefs } from 'pinia'
import { useBlockTypeStore } from '@/domain/blockList/services/useBlockTypeStore'

export const useTheBlockStore = defineGlobalStore(
  'theBlockStore',
  createGlobalState(() => {
    const _blocksState = createShallowReactive<ITheBlock>()
    const _theBlocksMarkedForRemoval = reactive<Record<string, boolean>>({})
    const _isBlockMoving = ref(false)

    const fetchAll: TFetchAllBlocksAction = async (nodeId: string, refresh = false) => {
      for (const theBlock of await viewAny(nodeId)) {
        if (_blocksState[theBlock.id] && refresh) {
          _blocksState[theBlock.id].value = theBlock
        } else {
          _blocksState[theBlock.id] = shallowRef<ITheBlock>(theBlock)
        }
      }
    }

    const resetState = () => {
      for (const blockId in _blocksState) {
        delete _blocksState[blockId]
      }
    }

    const findById: TFindBlockAction = (
      blockId: string,
    ): ShallowRef<ITheBlock> | undefined => {
      return _blocksState[blockId] ?? undefined
    }

    const update: TUpdateBlockAction | IBlockListItemMenuEntryAction = async (
      block: ITheBlock,
    ) => {
      if (!block.id) {
        // eslint-disable-next-line no-console
        flare.reportMessage('Could not update empty theBlock')
        return
      }

      const isDocument = block.attributes.documentId === block.attributes.nodeId
      const cardStore = useCardsStore()
      const { topic: doc } = storeToRefs(cardStore)
      const interactionType = ENodeContentInteractions.intendedToUpdateContentOfANode
      const nodeLevel = isDocument ? ENodeLevels.document : ENodeLevels.parentOrChild
      const blockType = useBlockTypeStore().findByBlock(block)

      const properties = {
        interaction_type: interactionType,
        node_id: block.attributes.documentId,
        node_level: nodeLevel,
        content_type_name: blockType.attributes.title,
        content_type: blockType.id,
        route: router.currentRoute.value.name ?? CDocumentOriginUnknown,
        route_query: { ...(router.currentRoute.value.query ?? {}) },
        route_params: { ...(router.currentRoute.value.params ?? {}) },
      }

      captureWithUser(
        isDocument
          ? EUserEvents.interactedWithADocumentsContent
          : EUserEvents.interactedWithANodesContent,
        useDocumentOrigin().fromDocument(properties, doc.value),
      )

      block = await useBlockPayloadSchemaGuard().apply(block)
      _blocksState[block.id].value.attributes = block.attributes
      triggerRef(_blocksState[block.id])

      return await submitUpdateBlockIntention(block)
    }

    const create: TCreateNewBlockAction | IBlockListItemMenuEntryAction = async (
      newBlock: ITheBlock,
    ) => {
      const isDocument = newBlock.attributes.documentId === newBlock.attributes.nodeId
      const cardStore = useCardsStore()
      const { topic: doc } = storeToRefs(cardStore)
      const interactionType = ENodeContentInteractions.intendedToCreateContentForANode
      const nodeLevel = isDocument ? ENodeLevels.document : ENodeLevels.parentOrChild
      const blockType = useBlockTypeStore().findByBlock(newBlock)

      const properties = {
        interaction_type: interactionType,
        node_id: newBlock.attributes.documentId,
        node_level: nodeLevel,
        content_type_name: blockType.attributes.title,
        content_type: blockType.id,
        route: router.currentRoute.value.name ?? CDocumentOriginUnknown,
        route_query: { ...(router.currentRoute.value.query ?? {}) },
        route_params: { ...(router.currentRoute.value.params ?? {}) },
      }

      captureWithUser(
        isDocument
          ? EUserEvents.interactedWithADocumentsContent
          : EUserEvents.interactedWithANodesContent,
        useDocumentOrigin().fromDocument(properties, doc.value),
      )

      newBlock = await useBlockPayloadSchemaGuard().apply(newBlock)
      _blocksState[newBlock.id] = shallowRef(newBlock)

      // apply new order locally that results because of the newBlock
      // notice we have to do it before the api submission to create the illusion of a
      // snappy app
      for (const existingBlockId in _blocksState) {
        if (existingBlockId === newBlock.id) {
          continue // don't touch the newBlock
        }

        if (
          _blocksState[existingBlockId].value.attributes.previousId ===
          newBlock.attributes.previousId
        ) {
          // put the newBlock at the head of the remaining blocks
          _blocksState[existingBlockId].value.attributes.previousId = newBlock.id
          triggerRef(_blocksState[existingBlockId])
        }
      }

      // submit to api
      const newBlockList = await submitCreateBlockIntention(newBlock)

      // sync the api blocks order with the local blocksState
      for (const newBlock of newBlockList) {
        _blocksState[newBlock.id].value.attributes.previousId =
          newBlock.attributes.previousId
        triggerRef(_blocksState[newBlock.id])
      }
    }

    const remove: TRemoveBlockAction | IBlockListItemMenuEntryAction = async (
      removedBlock: ITheBlock,
    ) => {
      const isDocument =
        removedBlock.attributes.documentId === removedBlock.attributes.nodeId
      const cardStore = useCardsStore()
      const { topic: doc } = storeToRefs(cardStore)
      const interactionType = ENodeContentInteractions.intendedToDeleteContentOfANode
      const nodeLevel = isDocument ? ENodeLevels.document : ENodeLevels.parentOrChild
      const blockType = useBlockTypeStore().findByBlock(removedBlock)

      const properties = {
        interaction_type: interactionType,
        node_id: removedBlock.attributes.documentId,
        node_level: nodeLevel,
        content_type_name: blockType.attributes.title,
        content_type: blockType.id,
        route: router.currentRoute.value.name ?? CDocumentOriginUnknown,
        route_query: { ...(router.currentRoute.value.query ?? {}) },
        route_params: { ...(router.currentRoute.value.params ?? {}) },
      }

      captureWithUser(
        isDocument
          ? EUserEvents.interactedWithADocumentsContent
          : EUserEvents.interactedWithANodesContent,
        useDocumentOrigin().fromDocument(properties, doc.value),
      )

      const removedBlockId = removedBlock.id
      _theBlocksMarkedForRemoval[removedBlockId] = true

      const newTheBlockList = await submitRemoveBlockIntention(removedBlockId)

      // trigger undo notification
      useRestoreHistory().setUndoNotification({
        type: 'theBlock',
        id: removedBlockId,
      })

      // apply new order to reactive block list
      for (const newTheBlock of newTheBlockList) {
        _blocksState[newTheBlock.id].value.attributes.previousId =
          newTheBlock.attributes.previousId
        triggerRef(_blocksState[newTheBlock.id])
      }

      // apply block removal to the frontend
      if (Object.values(_blocksState).length > newTheBlockList.length) {
        const currentTheBlockIds = Object.values(_blocksState).map(
          (theBlock: ShallowRef<ITheBlock>) => theBlock.value.id,
        )
        const newTheBlockIds = newTheBlockList.map(
          (theBlock: IShallowTheBlock) => theBlock.id,
        )

        const diff = difference(currentTheBlockIds, newTheBlockIds)
        for (const theBlockId of diff) {
          _blocksState[theBlockId] && delete _blocksState[theBlockId]
        }
      }

      // remove removal marker
      _theBlocksMarkedForRemoval[removedBlockId] &&
        delete _theBlocksMarkedForRemoval[removedBlockId]
    }

    const restore: TRestoreBlockAction = async (restoreBlockId: string) => {
      for (const block of await submitRestoreBlockIntention(restoreBlockId)) {
        if (!_blocksState[block.id]) {
          _blocksState[block.id] = shallowRef(block)
          continue
        }

        _blocksState[block.id].value.attributes.previousId = block.attributes.previousId
      }
    }

    const removalList = computed(() => {
      const blocksToBeRemoved: string[] = []
      for (const blockId in _theBlocksMarkedForRemoval) {
        blocksToBeRemoved.push(blockId)
      }
      return blocksToBeRemoved
    })

    const computedSortedBlocks = useComputedSortedLinkedItemList<ITheBlock>(
      _blocksState,
      toSortedFlattenedLinkedItemList,
      applyOrderToFlattenedLinkableItem,
      submitReorderBlocksIntention,
    )

    const lastBlock = () => {
      return (
        computedSortedBlocks.value[computedSortedBlocks.value.length - 1]?.value ??
        undefined
      )
    }

    return {
      // getter
      blocksState: _blocksState,
      sortedBlocks: computedSortedBlocks,
      isBlockMoving: _isBlockMoving,
      removalList,

      lastBlock,
      lastBlockId: () => lastBlock()?.id,

      // actions
      fetchAll,
      findById,
      create,
      update,
      remove,
      restore,
      resetState,
    }
  }),
)
