import type { RouteRecordRaw } from 'vue-router'
import { CContentRouteNames } from '@/domain/Content/contracts/CContentRouteNames'

export const blogRoutes: RouteRecordRaw[] = [
  {
    path: '/blog',
    name: CContentRouteNames.blog.index,
    component: () => import('@/domain/Content/components/PContent.vue'),
  },
  {
    path: '/blog/:slug',
    name: CContentRouteNames.blog.show,
    component: () => import('@/domain/Blog/components/PBlog.vue'),
    props: {
      contentModel: 'blog-page',
    },
  },
]
