import router from '@/app/services/router'
import { useCardsStore } from '@/domain/cards/services/useCardsStore'
import { defineStore, storeToRefs } from 'pinia'
import { useCardNavigation } from './useCardNavigation'
import { captureWithUser } from '@/app/support/usePosthog'
import { useDocumentOrigin } from '@/domain/documents/composables/useDocumentOrigin'
import { EDocumentInteractions } from '@/domain/documents/contracts/EDocumentInteractions'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import { ENodeInteractions } from '@/app/contracts/ENodeInteractions'
import { useNodeTitle } from '@/domain/documents/composables/useDocumentTitle'
import { toPlain } from '@/support/toPlain'
import { isEmptyOrWhitespace } from '@/support/isEmptyOrWhitespace'
import { CDocumentOriginUnknown } from '@/domain/documents/contracts/CDocumentOriginUnknown'

type CardExpandedDialogStoreState = {
  dialogIsVisible: boolean
  expandedCard: any
  expandedCardSiblings: any[]
  expandedParentCardId: string | null
  type?: 'topic' | 'parent' | 'child'
  cardEnumeration?: string
}

export const useCardExpandedDialogStore = defineStore('useCardExpandedDialogStore', {
  state: () =>
    ({
      dialogIsVisible: false,
      expandedCard: {},
      expandedCardSiblings: [],
      expandedParentCardId: null,
      type: 'topic',
      cardEnumeration: '',
    }) as CardExpandedDialogStoreState,
  getters: {
    cardIsExpanded: (state) => state.dialogIsVisible === true,
  },
  actions: {
    async pushNewExpandedRoute(id: string) {
      const query = router.currentRoute.value.query
      await router.push({
        query: {
          ...query,
          expanded: id,
        },
      })
    },
    async open(
      card?: any,
      siblingCards?: any,
      parentCardId?: any,
      type?: any,
      cardEnumeration?: string | number,
    ) {
      await this.pushNewExpandedRoute(card.id)
      this.expandedCard = card
      this.expandedCardSiblings = siblingCards ?? []
      this.expandedParentCardId = parentCardId
      this.type = type
      this.cardEnumeration = cardEnumeration?.toString()

      this.dialogIsVisible = true
      await useCardsStore().fetchChildrenOf(card)
      useCardNavigation().addIdsOfChildrenIfNotExist(card)
    },
    toggle(card) {
      this.dialogIsVisible ? this.close() : this.open(card)
    },
    close(force = true) {
      const cardsStore = useCardsStore()
      const { topic: doc } = storeToRefs(cardsStore)

      const query = router.currentRoute.value.query
      const node = router.currentRoute.value.query.expanded
        ? cardsStore.findCardById(router.currentRoute.value.query.expanded as string)
        : undefined
      const nodeIndex = node ? cardsStore.findCardEnumeration(node.id) : null

      delete query['expanded']
      router.push({
        query,
        force,
      })
      this.dialogIsVisible = false
      this.$reset()

      if (!node) {
        return
      }

      const documentProperties = useDocumentOrigin().fromDocument(
        {
          interaction_type: EDocumentInteractions.collapsedADocument,
          node_id: node?.id ?? CDocumentOriginUnknown,
          node_enumeration: isEmptyOrWhitespace(nodeIndex) ? 0 : nodeIndex,
          node_title: node ? toPlain(useNodeTitle(node)) : CDocumentOriginUnknown,
          route: router.currentRoute.value.name,
          route_query: { ...router.currentRoute.value.query },
          route_params: { ...router.currentRoute.value.params },
        },
        doc.value,
      )

      if (node.attributes?.cardType !== 'topic') {
        documentProperties.interaction_type = ENodeInteractions.collapsedANode
      }

      captureWithUser(
        node.attributes?.cardType !== 'topic'
          ? EUserEvents.interactedWithANode
          : EUserEvents.interactedWithADocument,
        documentProperties,
      )
    },
    findIndex() {
      return this.expandedCardSiblings.findIndex(
        (card) => card.id === this.expandedCard.id,
      )
    },
    setCardPosition(direction: 'forward' | 'backward') {
      let lastPosition = Number(this.cardEnumeration?.slice(-1))
      if (direction === 'forward') {
        lastPosition += 1
      } else {
        lastPosition -= 1
      }
      this.cardEnumeration = `${this.cardEnumeration?.slice(0, -1)}${lastPosition}`
    },
    expandCardBasedOnCardId(id: string) {
      const cardsStore = useCardsStore()
      const { topic, parents, children } = storeToRefs(cardsStore)
      const node = cardsStore.findCardById(id)
      if (!node) {
        return
      }
      const relationships = node?.relationships
      const documentId = relationships?.topic?.data?.id
      const parentNodeId = relationships?.parent?.data?.id
      const index = cardsStore.findCardEnumeration(id)

      const documentProperties = useDocumentOrigin().fromDocument(
        {
          interaction_type: EDocumentInteractions.expandedADocument,
          node_id: node.id,
          node_enumeration: isEmptyOrWhitespace(index) ? 0 : index,
          node_title: toPlain(useNodeTitle(node)),
          route: router.currentRoute.value.name,
          route_query: { ...router.currentRoute.value.query },
          route_params: { ...router.currentRoute.value.params },
        },
        topic.value,
      )

      if (node.attributes?.cardType === 'topic') {
        this.open(topic.value, [], undefined, 'topic', '')
        captureWithUser(EUserEvents.interactedWithADocument, documentProperties)
        return
      }

      documentProperties.interaction_type = ENodeInteractions.expandedANode
      this.open(
        node,
        documentId === parentNodeId ? parents.value : children.value[parentNodeId],
        documentId === parentNodeId ? documentId : parentNodeId,
        documentId === parentNodeId ? 'parent' : 'child',
        index,
      )
      captureWithUser(EUserEvents.interactedWithANode, documentProperties)
    },
  },
})
