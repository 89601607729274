<template>
  <FlexibleSectionLayout
    v-if="props.title"
    tag="section"
    :visualize-width="false"
    width="narrow"
    :class="componentName"
    :container-classes="containerClassesComputed"
    :content-container-classes="contentContainerClassesComputed"
  >
    <div class="w-full md:w-1/2">
      <div class="flex flex-col">
        <TextHeading
          :title-tag="props.titleTag ?? ''"
          :content="{ title: props.title, subline: props.subline, claim: props.claim }"
          :classes="{
            title: titleClassesComputed,
            subline: sublineClassesComputed,
            claim: claimClassesComputed,
          }"
        />

        <div
          v-if="(props.specs ?? []).length > 0"
          class="flex flex-col gap-2 lg:gap-3 py-2 mt-4"
        >
          <hr class="border-base-200" />
          <ul
            class="flex flex-row flex-wrap justify-start xs:justify-between md:justify-start lg:justify-between gap-4"
          >
            <SpecItem
              v-for="spec in specs"
              :key="spec.type"
              tag="li"
              :type="spec.type"
              :value="spec.value"
              container-classes="min-w-[30vw] xs:min-w-0 md:min-w-[16vw] lg:min-w-0"
            />
          </ul>
          <hr class="border-base-200" />
        </div>

        <CallToActionButton
          v-if="
            !hasSlotContent(slots?.default) &&
            props.cta?.title &&
            props.cta?.title.length > 0 &&
            props.cta?.target.length > 0 &&
            props.cta?.target.length > 0
          "
          :title="props.cta.title"
          action-type="url"
          element-classes="h-[40px] items-center justify-center rounded bg-state-error px-3.5 py-2 text-base lg:text-sm font-medium text-base-100 shadow-sm hover:bg-state-error-focus hover:cursor-pointer"
          :action-target="props.cta.target"
          class="pt-2 mt-4"
        />

        <div
          v-if="hasSlotContent(slots?.default)"
          class="flex flex-col w-full justify-center items-center md:items-start"
        >
          <slot />
        </div>
      </div>
    </div>

    <ImageOrVideo
      v-if="props.media"
      :source="
        props.media.type === EMediaType.image
          ? props.media.sourceFile
          : props.media.sourceUrl
      "
      :type="props.media.type"
      :alt="props.media.alt"
      :container-classes="props.media.containerClasses"
      :item-classes="props.media.itemClasses"
    />
  </FlexibleSectionLayout>
</template>

<script lang="ts">
export const componentName = 'CaseIntroSection'
</script>

<script lang="ts" setup>
import { computed, defineOptions, useSlots } from 'vue'
import FlexibleSectionLayout from '@/domain/Content/components/FlexibleSectionLayout.vue'
import ImageOrVideo from '@/domain/Content/components/ImageOrVideo.vue'
import { cva } from 'class-variance-authority'
import type { TCaseIntroContent } from '@/domain/Content/contracts/TCaseIntroContent'
import CallToActionButton from '@/domain/Content/components/CallToActionButton.vue'
import SpecItem from '@/domain/Content/components/SpecItem.vue'
import TextHeading from '@/domain/Content/components/TextHeading.vue'
import { EMediaType } from '@/domain/Content/contracts/EMediaType'
import { hasSlotContent } from '@/app/support/hasSlotContent'
import { EHeadline } from '@/domain/Content/contracts/EHeadline'
import { useOnePageEntryWithOptions } from '@/app/composables/useOnePageEntry'
import { toSpecs } from '@/domain/Book/support/toSpecs'
import type { TUsePageContentProps } from '@/domain/Book/contracts/TUsePageContentProps'
import { Environment } from '@/app/support/Environment'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<
    TCaseIntroContent & {
      titleClasses?: string
      sublineClasses?: string
      claimClasses?: string
      containerClasses?: string
      contentContainerClasses?: string
    } & TUsePageContentProps & {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        attributes?: any
      }
  >(),
  {
    titleTag: EHeadline.H2,
    containerClasses: undefined,
    contentContainerClasses:
      'flex-col-reverse md:flex-row gap-12 xl:gap-14 2xl:gap-18 justify-center items-center lg:items-start',
    usePageContent: false,
    pageContentModel: 'page',
  },
)

const slots = useSlots()

const containerClassesComputed = computed(() => cva()({ class: props.containerClasses }))

const contentContainerClassesComputed = computed(() =>
  cva()({ class: props.contentContainerClasses }),
)

const titleClassesComputed = computed(() =>
  cva('text-2xl')({ class: props.titleClasses }),
)
const sublineClassesComputed = computed(() => cva()({ class: props.sublineClasses }))
const claimClassesComputed = computed(() => cva()({ class: props.claimClasses }))

const { content: page, doFetch } = useOnePageEntryWithOptions(props.pageContentModel, {
  useAutoFetch: false,
  useEnvironment: Environment.current(),
})

if (props.usePageContent) {
  doFetch({})
}

const specs = computed(() => {
  if (props.usePageContent) {
    return toSpecs(page)
  }

  return props.specs
})
</script>

<style scoped></style>
