export enum ENodeInteractions {
  expandedANode = 'expanded_a_node',
  collapsedANode = 'collapsed_a_node',

  intendedToMoveANode = 'intended_to_move_a_node',
  intendedToCreateANode = 'intended_to_create_a_node',
  intendedToDeleteANode = 'intended_to_delete_a_node',

  intendedToUpdateANodeTitle = 'intended_to_update_a_node_title',
  intendedToUpdateANodeStatus = 'intended_to_update_a_node_status',

  intendedToDetailANodeWithAdditionalSteps = 'intended_to_detail_a_node_with_additional_steps',
  intendedToExplainANode = 'intended_to_explain_a_node',

  // @todo intendedToRestoreANode = 'intended_to_restore_a_node',
}
