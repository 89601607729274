export const injectCopyIdIntoHeaders = (
  headers: Record<string, string>,
  copyId?: string,
) => {
  if (copyId) {
    headers['x-related-copy-id'] = copyId
  }

  return headers
}
