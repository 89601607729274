import { Ref } from 'vue'
import { EAuthenticationProviderSignInFlowStates } from '@/domain/Authentication/contracts/EAuthenticationProviderSignInFlowStates'

import { EAuthenticationProviders } from '@/domain/Authentication/contracts/EAuthenticationProviders'
import type { TSignInOptions } from '@/domain/Authentication/contracts/TSignInOptions'
import type { TSignInWithProviderAction } from '@/domain/Authentication/contracts/TSignInWithProviderAction'
import { useUserStore } from '@/app/services/useUserStore'
import { resolveAfterSignInRedirectTarget } from '@/domain/Authentication/support/resolveAfterSignInRedirectTarget'
import type { TRouteResolver } from '@/domain/Authentication/contracts/TRouteResolver'
import type { TCurrentAuthenticationState } from '@/domain/Authentication/contracts/TCurrentAuthenticationState'
import { EAuthenticationSecretTypes } from '@/domain/Authentication/contracts/EAuthenticationSecretTypes'
import { requestAuthenticationDocumentBySecretType } from '@/domain/Authentication/services/requestAuthenticationDocumentBySecretType'
import type { TSignInContext } from '@/domain/Authentication/contracts/TSignInContext'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'
import {
  captureWithUser,
  injectCurrentDistinctIntoHeaders,
} from '@/app/support/usePosthog'
import { EAuthenticationEvents } from '@/domain/Authentication/contracts/EAuthenticationEvents'
import { useNavigator } from '@/app/composables/useNavigator'
import { CAuthenticationRouteNames } from '@/domain/Authentication/contracts/CAuthenticationRouteNames'
import { injectCopyIdIntoHeaders } from '@/domain/Authentication/support/injectCopyIdIntoHeaders'

export const signInWithGoogle: TSignInWithProviderAction = async (
  status: Ref<EAuthenticationProviderSignInFlowStates>,
  selectedProvider: Ref<EAuthenticationProviders>,
  signInContext: Readonly<Ref<TSignInContext>>,
  routeResolver: TRouteResolver,
  reset: () => TCurrentAuthenticationState,
  options?: TSignInOptions,
): Promise<void> => {
  if (selectedProvider.value !== EAuthenticationProviders.GOOGLE) {
    // eslint-disable-next-line
    console.error(
      `Provider '${selectedProvider}' not support, '${EAuthenticationProviders.GOOGLE}' is required`,
    )
    return
  }

  const nonRefSignInContext = signInContext.value
  try {
    const headers = injectCurrentDistinctIntoHeaders({})
    injectCopyIdIntoHeaders(headers, signInContext.value?.share_id)

    const authenticationDocument = await requestAuthenticationDocumentBySecretType(
      EAuthenticationSecretTypes.GOOGLE,
      options?.googleSignInPayload?.credential as string,
      undefined,
      headers,
    )

    if (!authenticationDocument) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error('Did not receive JWT payload')
    }

    useAuthentication().storeJwt(authenticationDocument.attributes.payload)
    await useUserStore().refresh()
    status.value = EAuthenticationProviderSignInFlowStates.SUCCEEDED

    const navigationTarget = resolveAfterSignInRedirectTarget(
      routeResolver,
      nonRefSignInContext,
      false,
    )

    const afterNavigationCallback = (): Promise<void> => {
      captureWithUser(EAuthenticationEvents.signedInSuccessfully, {
        status: status.value,
        provider: selectedProvider.value,
        signInContext: { ...nonRefSignInContext },
      })
      reset()
      return Promise.resolve()
    }

    await useNavigator().push(navigationTarget, afterNavigationCallback)
  } catch (error) {
    const errorMessage = (error as Partial<{ message?: string }>).message ?? undefined
    status.value = EAuthenticationProviderSignInFlowStates.FAILED

    const _status = status.value
    const _provider = selectedProvider.value

    const afterNavigationCallback = (): Promise<void> => {
      captureWithUser(EAuthenticationEvents.signInFailed, {
        status: _status,
        provider: _provider,
        signInContext: { ...nonRefSignInContext },
        errorMessage,
      })

      // @todo what should we do here? triggering an user notification?
      // @hint UserNotification

      reset()
      return Promise.resolve()
    }

    await useNavigator().push(
      {
        name: CAuthenticationRouteNames.signIn,
      },
      afterNavigationCallback,
    )
  }
}
