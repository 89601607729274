import { Router, useRouter } from 'vue-router'
import { getSharingInformation } from '@/domain/cards/services/cardClient'
import type { TDocumentOrigin } from '@/domain/documents/contracts/TDocumentOrigin'
import type { TSharingInformation } from '@/domain/cards/contracts/TSharingInformation'
import { flare } from '@flareapp/flare-client'
import { EDocumentOriginType } from '@/domain/documents/contracts/EDocumentOriginType'
import { useMetadata } from '@/domain/documents/composables/useMetadata'
import { CDocumentOriginUnknown } from '@/domain/documents/contracts/CDocumentOriginUnknown'
import { isEmptyOrWhitespace } from '@/support/isEmptyOrWhitespace'

const resolveFromSnapshotInformation = async (
  ref?: string,
  router?: Router,
  information?: TSharingInformation,
): Promise<(Record<string, unknown> & { document?: TDocumentOrigin }) | undefined> => {
  if (!ref || !ref.includes('share-links')) {
    // eslint-disable-next-line no-console
    console.error('Could not resolve document origin from ref', ref)
    return Promise.resolve(undefined)
  }

  if (!router) {
    router = useRouter()
    // eslint-disable-next-line no-console
    console.warn('router is missing fall back to useRouter()')
  }

  const targetPath = ref.includes('http') ? new URL(ref).pathname : ref
  const targetRoute = router.resolve(targetPath)

  const shareId = targetRoute.params?.shareId as string | undefined

  let info: TSharingInformation | undefined | null

  try {
    info = information
      ? information
      : shareId
      ? await getSharingInformation(shareId)
      : undefined
  } catch (e) {
    const errorTitle = 'Could not resolve DocumentOrigin'
    flare.report(e as Error, {
      errorTitle: errorTitle,
      ref,
    })
    // eslint-disable-next-line no-console
    console.error(errorTitle, { ref, error: e })
  }

  return {
    id: CDocumentOriginUnknown,
    title: info?.data.attributes.documentTitle ?? CDocumentOriginUnknown,
    origin_type: EDocumentOriginType.snapshot,
    origin_id: info?.data.attributes.documentSnapshotId ?? CDocumentOriginUnknown,
    origin_ref: info?.data.id ?? CDocumentOriginUnknown,
    origin_source: info?.data.attributes.sourceDocumentId ?? CDocumentOriginUnknown,
  }
}

const _fromPrompt = (
  properties: Record<string, unknown>,
  prompt: string,
  documentId?: string,
): Record<string, unknown> & { document?: TDocumentOrigin } => {
  properties['document'] = {
    title: prompt ?? CDocumentOriginUnknown,
    id: documentId ?? CDocumentOriginUnknown,
    origin_type: EDocumentOriginType.prompt,
    origin_id: CDocumentOriginUnknown,
    origin_ref: prompt ?? CDocumentOriginUnknown,
    origin_source: prompt ?? CDocumentOriginUnknown,
  }
  return properties
}

const _resolveOriginProperties = (
  // eslint-disable-next-line
  document: any,
) => {
  const metadata = useMetadata(document)
  const fromSnapshot = metadata.documentFromSnapshot()
  const fromPrompt = metadata.documentFromPrompt()
  let title = metadata.title()?.value ?? CDocumentOriginUnknown

  if (title === CDocumentOriginUnknown && !isEmptyOrWhitespace(document?.title)) {
    title = document.title
  }

  let originType = EDocumentOriginType.document
  let originId = document.id
  let originSource = originId
  let originRef = originId

  if (fromSnapshot !== undefined) {
    originType = EDocumentOriginType.snapshot
    originId = fromSnapshot.templateId
    originSource = fromSnapshot.sourceId
    originRef = 'snapshot_sharing.id (db:shared_topics.id) – not resolvable'
  }

  if (fromPrompt !== undefined && !fromSnapshot) {
    originType = EDocumentOriginType.prompt
    originId = CDocumentOriginUnknown
    originSource =
      (
        (fromPrompt?.prompt?.request?.messages as { role: string; content: string }[]) ??
        []
      ).filter((message) => message?.role && message.role === 'user')[0].content ??
      CDocumentOriginUnknown
    originRef = originSource
  }

  return {
    title,
    origin_type: originType,
    origin_id: originId, // snapshot.id/template.id | unknown (prompt) | document.id
    origin_source: originSource ?? CDocumentOriginUnknown, // source document.id, prompt, document.id
    origin_ref: originRef ?? CDocumentOriginUnknown, // shareid, prompt, document.id
  }
}

const _fromDocument = (
  properties: Record<string, unknown>,
  document?: {
    id: string
    title?: string
  },
): Record<string, unknown> & { document?: TDocumentOrigin } => {
  if (!document) {
    return properties
  }

  properties['document'] = {
    id: document.id ?? undefined,
    ..._resolveOriginProperties(document),
  }

  return properties
}

export const useDocumentOrigin = (deps?: { router?: Router }) => {
  return {
    fromSnapshot: async (
      properties: Record<string, unknown>,
      ref?: string,
      information?: TSharingInformation,
    ): Promise<Record<string, unknown> & { document?: TDocumentOrigin }> => {
      const documentOrigin = await resolveFromSnapshotInformation(
        ref,
        deps?.router,
        information,
      )

      if (documentOrigin) {
        properties['document'] = documentOrigin
      }

      return properties
    },
    fromDocument: _fromDocument,
    fromPrompt: _fromPrompt,
  }
}
