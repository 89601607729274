import type { TSignInWithProviderAction } from '@/domain/Authentication/contracts/TSignInWithProviderAction'
import { ref, Ref, watch } from 'vue'
import { EAuthenticationProviderSignInFlowStates } from '@/domain/Authentication/contracts/EAuthenticationProviderSignInFlowStates'
import { EAuthenticationProviders } from '@/domain/Authentication/contracts/EAuthenticationProviders'
import type { TSignInContext } from '@/domain/Authentication/contracts/TSignInContext'
import type { TRouteResolver } from '@/domain/Authentication/contracts/TRouteResolver'
import type { TCurrentAuthenticationState } from '@/domain/Authentication/contracts/TCurrentAuthenticationState'
import type { TSignInOptions } from '@/domain/Authentication/contracts/TSignInOptions'
import { EXSignInStages } from '@/domain/Authentication/contracts/EXSignInStages'
import { requestAuthenticationDocumentBySecretType } from '@/domain/Authentication/services/requestAuthenticationDocumentBySecretType'
import { EAuthenticationSecretTypes } from '@/domain/Authentication/contracts/EAuthenticationSecretTypes'
import { useXPopupWindow } from '@/domain/Authentication/composables/useXPopupWindow'
import { useXOAuthStore } from '@/domain/Authentication/composables/useXOAuthStore'
import {
  captureWithUser,
  injectCurrentDistinctIntoHeaders,
} from '@/app/support/usePosthog'
import { EAuthenticationEvents } from '@/domain/Authentication/contracts/EAuthenticationEvents'
import type { TAuthenticationDocument } from '@/domain/Authentication/contracts/TAuthenticationDocument'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'
import { useUserStore } from '@/app/services/useUserStore'
import { resolveAfterSignInRedirectTarget } from '@/domain/Authentication/support/resolveAfterSignInRedirectTarget'
import { useNavigator } from '@/app/composables/useNavigator'
import { CAuthenticationRouteNames } from '@/domain/Authentication/contracts/CAuthenticationRouteNames'
import { useXAuthenticationRetry } from '@/domain/Authentication/composables/useXAuthenticationRetry'
import { useNotificationsStore } from '@/domain/notifications/services/useNotificationsStore'
import { createNotification } from '@/domain/notifications/services/notificationFactory'
import { injectCopyIdIntoHeaders } from '@/domain/Authentication/support/injectCopyIdIntoHeaders'
import { flare } from '@flareapp/flare-client'

const doSignIn = async (
  oauthToken: string,
  oauthVerifier: string,
  status: Ref<EAuthenticationProviderSignInFlowStates>,
  selectedProvider: Ref<EAuthenticationProviders>,
  signInContext: Readonly<Ref<TSignInContext>> | Ref<TSignInContext>,
  routeResolver: TRouteResolver,
  reset: () => TCurrentAuthenticationState,
) => {
  const stage = ref(EXSignInStages.REQUEST_JWT)
  const nonRefSignInContext = signInContext.value

  try {
    const headers = injectCurrentDistinctIntoHeaders({})
    injectCopyIdIntoHeaders(headers, signInContext.value?.share_id)

    const authenticationDocument = await requestAuthenticationDocumentBySecretType(
      EAuthenticationSecretTypes.X_TOKEN_AND_VERIFIER,
      oauthToken,
      { ...signInContext.value, oauth_verifier: oauthVerifier },
      headers,
    )

    if (authenticationDocument === undefined) {
      flare.report(new Error('AuthenticationDocument is undefined'), {
        status: EAuthenticationProviderSignInFlowStates.INVALID,
        provider: EAuthenticationProviders.X,
        signInContext: { ...nonRefSignInContext },
        stage: stage.value,
      })

      // @info X auth trigger redirect multiple times so the jwt request is
      //  performed multiple time but failing after one successful attempt
      //  as a workaround we silence these duplicated requests as invalid
      return
    }

    useAuthentication().storeJwt(authenticationDocument.attributes.payload)
    await useUserStore().refresh()

    status.value = EAuthenticationProviderSignInFlowStates.SUCCEEDED

    const navigationTarget = resolveAfterSignInRedirectTarget(
      routeResolver,
      nonRefSignInContext,
      false,
    )

    const afterNavigationCallback = async (): Promise<void> => {
      captureWithUser(EAuthenticationEvents.signedInSuccessfully, {
        status: status.value,
        provider: EAuthenticationProviders.X,
        signInContext: { ...nonRefSignInContext },
        stage: stage.value,
      })
      reset()
      await useXOAuthStore().reset()
      useXAuthenticationRetry().reset()
      return Promise.resolve()
    }

    await useNavigator().push(navigationTarget, afterNavigationCallback)
  } catch (error) {
    const errorMessage = (error as Partial<{ message?: string }>).message ?? undefined
    const nonRefSignInContext = signInContext.value
    status.value = EAuthenticationProviderSignInFlowStates.FAILED

    const _status = status.value
    const _provider = EAuthenticationProviders.X

    const afterNavigationCallback = (): Promise<void> => {
      captureWithUser(EAuthenticationEvents.signInFailed, {
        status: _status,
        provider: _provider,
        stage: stage.value,
        signInContext: { ...nonRefSignInContext },
        errorMessage,
      })

      // @todo what should we do here? triggering an user notification?
      // @hint UserNotification

      reset()
      useXAuthenticationRetry().reset()
      return Promise.resolve()
    }

    await useNavigator().push(
      {
        name: CAuthenticationRouteNames.signIn,
      },
      afterNavigationCallback,
    )
  }
}

export const signInWithX: TSignInWithProviderAction = async (
  status: Ref<EAuthenticationProviderSignInFlowStates>,
  selectedProvider: Ref<EAuthenticationProviders>,
  signInContext: Readonly<Ref<TSignInContext>>,
  routeResolver: TRouteResolver,
  reset: () => TCurrentAuthenticationState,
  options?: TSignInOptions,
) => {
  useNotificationsStore().removeExposedNotification()

  if (selectedProvider.value !== EAuthenticationProviders.X) {
    // eslint-disable-next-line
    console.error(
      `Provider '${selectedProvider}' not support, '${EAuthenticationProviders.X}' is required`,
    )
    return
  }

  if (!options?.stage) {
    throw new Error(`Required options are missing for '${selectedProvider.value}'`)
  }

  if (!Object.values(EXSignInStages).includes(options.stage as EXSignInStages)) {
    throw new Error(`Required options are missing for '${selectedProvider.value}'`)
  }

  const nonRefSignInContext = signInContext.value

  watch(
    () => useXOAuthStore().state.value,
    (newState) => {
      const { oauthToken, oauthVerifier } = newState

      if (!oauthToken || !oauthVerifier) {
        return
      }

      status.value = EAuthenticationProviderSignInFlowStates.STARTED
      selectedProvider.value = EAuthenticationProviders.X
      const signInContext = ref(nonRefSignInContext)

      doSignIn(
        oauthToken,
        oauthVerifier,
        status,
        selectedProvider,
        signInContext,
        routeResolver,
        reset,
      )
    },
  )

  let authenticationDocument: TAuthenticationDocument | undefined = undefined

  try {
    const headers = injectCurrentDistinctIntoHeaders({})
    injectCopyIdIntoHeaders(headers, signInContext.value?.share_id)

    authenticationDocument = await requestAuthenticationDocumentBySecretType(
      EAuthenticationSecretTypes.X_EMPTY,
      'empty',
      { ...signInContext.value },
      headers,
    )

    if (!authenticationDocument) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error('Could not resolve redirect')
    }

    captureWithUser(EAuthenticationEvents.signedInSuccessfully, {
      status: EAuthenticationProviderSignInFlowStates.SUCCEEDED,
      provider: selectedProvider.value,
      signInContext: { ...nonRefSignInContext },
      stage: EXSignInStages.REQUEST_REDIRECT,
    })
    reset()
  } catch (error) {
    const errorMessage = (error as Partial<{ message?: string }>).message ?? undefined
    status.value = EAuthenticationProviderSignInFlowStates.FAILED

    const _status = status.value
    const _provider = selectedProvider.value

    captureWithUser(EAuthenticationEvents.signInFailed, {
      status: _status,
      provider: _provider,
      signInContext: { ...nonRefSignInContext },
      stage: EXSignInStages.REQUEST_REDIRECT,
      errorMessage,
    })
    reset()
  }

  await useXOAuthStore().reset()

  const {
    hasTriedExactlyOnce,
    increment: incrementRetryCount,
    reset: resetRetryCount,
  } = useXAuthenticationRetry()
  const _selectedProvider = EAuthenticationProviders.X

  const executeUseXPopupWindow = () =>
    useXPopupWindow(authenticationDocument?.attributes.payload as string, () => {
      if (hasTriedExactlyOnce.value) {
        incrementRetryCount()
        const notification = createNotification(
          `Signing in with X was interrupted. <br> Please try a second time.`,
          'warning',
        )
        useNotificationsStore().exposeOneNotification(notification, 10)
        return
      }

      useXOAuthStore().reset()
      const _status = EAuthenticationProviderSignInFlowStates.FAILED
      captureWithUser(EAuthenticationEvents.aborted, {
        status: _status,
        provider: _selectedProvider,
        signInContext: { ...signInContext.value },
        stage: EXSignInStages.REQUEST_OAUTH_TOKEN,
        errorMessage: `User closed '${_selectedProvider}' sign in popup manually`,
      })
      resetRetryCount()
    }).open()

  incrementRetryCount()
  executeUseXPopupWindow()
}
