import { deepUnref } from 'vue-deepunref'
import { toRaw } from 'vue'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toPlain = (subject: any) => {
  if (typeof subject !== 'object') {
    return subject
  }

  return toRaw(deepUnref(subject))
}
