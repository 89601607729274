<template>
  <div
    :id="props.layoutId"
    class="relative z-0 flex w-full flex-col"
    :class="componentName"
  >
    <div
      class="sticky top-0 w-full z-20 flex justify-center items-center shadow"
      :class="[...props.navigation.containerClasses]"
    >
      <slot name="navigation" />
    </div>
    <div
      :id="props.scrollContainer.id"
      ref="scrollableContainer"
      class="relative z-0"
      :class="[...props.scrollContainer.classes]"
    >
      <slot name="scrollable-container" />
      <Footer />
    </div>
    <slot name="dialog" />
  </div>
</template>

<script lang="ts">
export const componentName = 'ScrollableContainerLayout'
</script>

<script lang="ts" setup>
import { ref, defineOptions } from 'vue'
import Footer from '@/app/components/Footer.vue'
import type { TScrollableContainerLayoutProps } from '@/app/contracts/TScrollableContainerLayoutProps'

defineOptions({
  name: componentName,
})

const props = withDefaults(defineProps<TScrollableContainerLayoutProps>(), {
  layoutId: 'scrollable-layout',
  navigation: () => {
    return {
      containerClasses: [],
      whenScrolledClasses: [],
    }
  },
  rootItemsSelectors: () => ['html', 'body'],
  scrollContainer: () => {
    return {
      id: 'scrollable-container',
      classes: [],
    }
  },
  inputs: () => {
    return {
      selectors: ['input', 'textarea'],
      scrollTargetSelector: '.input-scroll-target',
    }
  },
  hasFooter: false,
})

const scrollableContainer = ref(null)
</script>

<style scoped></style>
