/**
 * @deprecated This enum is marked as obsolete.
 */
export enum ESignInContentTypes {
  prompt = 'prompt_only',
  promptFromDirectory = 'prompt_from_directory',
  case = 'case_only',
  caseFromDirectory = 'case_from_directory',
  default = 'default',
}
